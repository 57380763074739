import React, { ReactElement } from "react";

export default function CheckIcon({
    className,
    fill,
}: {
    className?: string;
    fill?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 32 26"
            version="1.1"
            viewBox="0 0 32 26"
            xmlSpace="preserve"
            className={className ?? ""}
            fill={fill}
            style={{ fill: fill }}
        >
            <path d="M27.1 0L25 2.2 10.7 17.4l-4.3-4.3L4.2 11 0 15.2l2.1 2.1 6.5 6.5 2.2 2.2 2.1-2.2L29.4 6.3l2.1-2.2L27.1 0z"></path>
        </svg>
    );
}
