import React from "react";

export default function TooltipIcon() {
    return (
        <svg
            width="325"
            height="325"
            viewBox="0 0 325 325"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M162.402 0C119.332 0 78.023 17.109 47.562 47.562C17.109 78.019 0 119.328 0 162.402C0 205.476 17.109 246.781 47.562 277.242C78.019 307.695 119.328 324.804 162.402 324.804C205.476 324.804 246.781 307.695 277.242 277.242C307.695 246.785 324.804 205.476 324.804 162.402C324.804 119.328 307.695 78.023 277.242 47.562C246.785 17.109 205.476 0 162.402 0V0ZM162.402 308C123.785 308 86.754 292.66 59.452 265.352C32.143 238.047 16.804 201.016 16.804 162.402C16.804 123.788 32.144 86.754 59.452 59.452C86.757 32.143 123.788 16.804 162.402 16.804C201.016 16.804 238.05 32.144 265.352 59.452C292.661 86.757 308 123.788 308 162.402C308 201.016 292.66 238.05 265.352 265.352C238.047 292.661 201.016 308 162.402 308Z" />
            <path d="M151.202 134.4L145.6 140.279V240.519L151.202 246.398H173.6L179.202 240.519V140.279L173.6 134.4H151.202Z" />
            <path d="M151.202 78.4L145.6 84.0016V106.4L151.202 112.001H173.6L179.202 106.4V84.0016L173.6 78.4H151.202Z" />
        </svg>
    );
}
