import { AffiliateOfferZone } from "@lib/shared/config";
import { getAffiliateListId } from "@lib/shared/cookies";
import { ListingItem } from "@lib/shared/types";
import { CookieValueTypes } from "cookies-next/lib/types";
import React, { useEffect, useState } from "react";
import { callEvent } from "src/api";
export default function AffiliateOfferUrl({
    className,
    children,
    customHref,
    offer,
    target,
    style,
    zone,
}: {
    className?: string;
    children?: React.ReactNode;
    customHref?: string;
    offer: ListingItem | undefined | null;
    target?: React.HTMLAttributeAnchorTarget | undefined;
    style?: React.CSSProperties | undefined;
    zone: AffiliateOfferZone;
}) {
    const [listId, setListId] = useState<CookieValueTypes | null>(null);

    useEffect(() => {
        setListId(getAffiliateListId());
    }, [getAffiliateListId]);

    const queryParams = [];

    if (listId) {
        queryParams.push(`id=${listId as string}`);
    }

    if (offer?.category?.id) {
        queryParams.push(`categoryId=${offer.category.id.toString()}`);
    }

    if (zone) {
        queryParams.push(`zone=${zone}`);
    }

    return (
        <a
            style={style}
            target={target}
            className={className ?? ""}
            rel="noreferrer"
            href={
                customHref ??
                `/offer/${offer?.slug ?? ""}?${queryParams.join("&")}`
            }
            onClick={() => {
                if (customHref) {
                    void callEvent({
                        event: "Call",
                        phoneNumber: customHref?.split("tel:")[1] ?? "",
                    });
                }
            }}
        >
            {children}
        </a>
    );
}
