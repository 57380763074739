import React, { ReactElement } from "react";

export default function Loader({
    size,
    className,
}: {
    size: number;
    className?: string;
}): ReactElement {
    return (
        <div className={`flex items-center ${className ?? ""}`}>
            <span
                className="animate-loader-spin"
                style={{ width: `${size}px`, height: `${size}px` }}
                role="progressbar"
                color="white"
            >
                <svg className="block" viewBox="22 22 44 44">
                    <circle
                        style={{
                            stroke: "currentcolor",
                            strokeDasharray: "80px,200px",
                            strokeDashoffset: 0,
                        }}
                        cx="44"
                        cy="44"
                        r="20.2"
                        fill="none"
                        strokeWidth="3.6"
                    ></circle>
                </svg>
            </span>
        </div>
    );
}
