import { Field } from "@lib/shared/types";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

interface InputProps {
    field: Field;
    onChange: (val: string) => void;
    onBlur: (val: string) => void;
    value: string;
    className: string;
}

export default function TextArea(props: InputProps): ReactElement {
    const { value, onChange, onBlur, field, className } = props;
    const { maxValue, minValue } = field;

    return (
        <textarea
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={`${styles["field"]} ${styles["textarea"]} ${
                className ?? ""
            }`}
            placeholder={field.placeholder ?? undefined}
            onBlur={onBlur}
            maxLength={
                maxValue?.type === "number"
                    ? parseInt(maxValue.value as string)
                    : undefined
            }
            minLength={
                minValue?.type === "number"
                    ? parseInt(minValue.value as string)
                    : undefined
            }
            id={field.codeName}
        />
    );
}
