import React, { ReactElement } from "react";
import colors from "@styles/variables/businessphonepricesVariables.module.scss";
import { formatPhoneNumber, getPhoneNumber } from "@lib/sharedUtils";
import style from "./style.module.scss";
import { ListingItem } from "@lib/shared/types";
import AffiliateOfferUrl from "../affiliateOfferUrl";
import { AffiliateOfferZone } from "@lib/shared/config";

export default function OfferVertical({
    offer,
    zone,
    displayLinkAndPhoneNumber,
    customStyle,
}: {
    offer: ListingItem;
    zone: AffiliateOfferZone;
    displayLinkAndPhoneNumber?: boolean;
    customStyle?: {
        fixedHeight?: boolean;
        wrapperStyle?: string;
        buttonBgColor?: string;
    };
}): ReactElement {
    const phoneNumber = getPhoneNumber(offer);

    const phoneNumberMessage = offer?.attributes.phoneNumberMessage
        ? offer?.attributes.phoneNumberMessage
        : offer?.hiddenAttributes.phoneNumberMessage
        ? offer?.hiddenAttributes.phoneNumberMessage
        : "";

    return (
        <div
            className={`${
                style.container
            }  relative  z-50 mb-[30px] px-4 py-4 border rounded-5 ${
                customStyle?.wrapperStyle ?? ""
            }`}
            style={{
                borderColor: "#e3e3e3",
            }}
        >
            <div className="flex flex-col flex-wrap items-center">
                <div
                    className={`w-40 mb-4 flex justify-center ${
                        customStyle?.fixedHeight ? "h-20" : ""
                    }`}
                    style={{
                        borderColor: colors.offerBorderColor,
                    }}
                >
                    {offer.logo && (
                        <img src={offer.logo.fullPath} alt={offer.title} />
                    )}
                </div>
                <div>
                    <div className="w-full sm:w-[200px]  order-1 sm:order-2 flex-grow mt-0">
                        <h2
                            style={{ color: "#464c4d" }}
                            className="text-center text-[14px]"
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        phoneNumberMessage ??
                                        "Review Offers and Coverage",
                                }}
                            />
                        </h2>
                    </div>
                    <div className="order-3 flex flex-col mt-2 w-auto">
                        <AffiliateOfferUrl
                            zone={zone}
                            style={{
                                color: "white",
                                backgroundColor:
                                    customStyle?.buttonBgColor ??
                                    colors.autumnGlory,
                            }}
                            className={`${
                                !displayLinkAndPhoneNumber ? style.link : ""
                            } flex justify-center rounded-5 text-center px-[10px] py-[10px] text-[15px]`}
                            offer={offer}
                            customHref={
                                phoneNumber
                                    ? `tel:${phoneNumber ?? ""}`
                                    : undefined
                            }
                            target="_blank"
                        >
                            {formatPhoneNumber(phoneNumber) ?? "Let's Go"}
                        </AffiliateOfferUrl>
                        {displayLinkAndPhoneNumber && (
                            <AffiliateOfferUrl
                                zone={zone}
                                className="text-left lg:text-center py-3 rounded-lg"
                                offer={offer}
                                target="_blank"
                                style={{
                                    color: "#0e5aa9",
                                }}
                            >
                                <span>Let's Go</span>
                            </AffiliateOfferUrl>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
