import ButtonArrow from "@components/shared/buttonArrow";
import CheckIcon from "@components/svg/checkIcon";
import { ListingItem } from "@lib/shared/types";
import React, { ReactElement } from "react";
import Image from "next/image";
import { formatPhoneNumber, getPhoneNumber } from "@lib/sharedUtils";
import AffiliateOfferUrl from "../affiliateOfferUrl";
import { AffiliateOfferZone, affiliateOfferZones } from "@lib/shared/config";
import { useDomainContext } from "@hooks/useDomainContext";
import styles from "./style.module.scss";
interface Props {
    offer: ListingItem | null;
    className?: string;
    displayLinkAndPhoneNumber?: boolean;
    style?: {
        background: string;
        borderColor: string;
        buttonBackground: string;
        buttonForground: string;
        borderStyle?: "all" | "top" | "bottom" | "none";
    };
    zone?: AffiliateOfferZone;
    fullWidth?: boolean;
}

export default function Banner({
    offer,
    className,
    displayLinkAndPhoneNumber,
    style,
    zone = affiliateOfferZones.HOME_PAGE,
    fullWidth = false,
}: Props): ReactElement {
    const { isBot } = useDomainContext();
    if (isBot) {
        return <></>;
    }
    const phoneNumber = getPhoneNumber(offer as ListingItem);

    const borderStyle: {
        border?: string;
        borderTop?: string;
        borderBottom?: string;
        borderLeft?: string;
        borderRight?: string;
    } = {};

    switch (style?.borderStyle) {
        case "all":
            borderStyle["border"] = "1px solid #e3e3e3";
            break;
        case "top":
            borderStyle["borderTop"] = "1px solid #e3e3e3";
            break;
        case "bottom":
            borderStyle["borderBottom"] = "1px solid #e3e3e3";
            break;
        case "none":
            borderStyle["border"] = "none";
            break;
        default:
            borderStyle["border"] = "1px solid #e3e3e3";
    }

    const buttonText = offer?.hiddenAttributes.buttonText ?? "Get Quote";

    return (
        <div
            className={`${
                fullWidth ? "max-w-7xl" : "max-w-6xl"
            } mx-auto mt-8 relative hover:shadow-xl transition-shadow ${
                className ?? ""
            }`}
        >
            <div
                className="flex flex-wrap flex-col lg:items-center lg:flex-row px-6 py-10"
                style={{
                    backgroundColor: style?.background ?? "#F6F6F6",
                    ...borderStyle,
                    borderColor: style?.borderColor ?? "#e3e3e3",
                }}
            >
                <div
                    className={`w-1/2 lg:w-1/4 flex items-center justify-start lg:justify-center pr-5 relative ${styles["imgWrapper"]}`}
                >
                    {offer?.logo && (
                        <Image
                            className="w-full"
                            src={offer?.logo?.fullPath}
                            style={{ objectFit: "contain" }}
                            layout="fill"
                            alt="offer logo"
                        />
                    )}
                </div>
                <div
                    className={`mt-5 lg:mt-0 lg:pr-8 lg:pl-6`}
                    style={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}
                >
                    <h2 className="font-bold text-lg lg:text-xl">
                        {offer?.description}
                    </h2>
                    {offer?.features && (
                        <ul className="mt-3">
                            {offer?.features
                                ?.slice(0, 5)
                                ?.map((feature, idx) => (
                                    <li
                                        style={{ color: "#3EA636" }}
                                        className="flex items-center mb-2"
                                        key={idx}
                                    >
                                        <span className="children:w-3 children:h-3 mr-2 children:fill-current">
                                            <CheckIcon />
                                        </span>
                                        <span style={{ color: "#5B5B5B" }}>
                                            {feature}
                                        </span>
                                    </li>
                                ))}
                        </ul>
                    )}
                </div>
                <div className="flex flex-col mt-3 lg:mt-0 ">
                    <AffiliateOfferUrl
                        zone={zone}
                        target={!phoneNumber ? "_blank" : undefined}
                        className={`text-center py-3 rounded-lg lg:px-6 ${
                            !displayLinkAndPhoneNumber
                                ? `before:absolute before:w-full before:h-full before:left-0 before:top-0`
                                : ""
                        }`}
                        style={{
                            backgroundColor:
                                style?.buttonBackground ?? "#3EA636",
                            color: style?.buttonForground ?? "white",
                            maxWidth: "300px",
                            minWidth: "200px",
                        }}
                        offer={offer}
                        customHref={
                            phoneNumber ? `tel:${phoneNumber ?? ""}` : undefined
                        }
                    >
                        <span>
                            {formatPhoneNumber(phoneNumber) ?? buttonText}

                            <ButtonArrow />
                        </span>
                    </AffiliateOfferUrl>

                    {displayLinkAndPhoneNumber && (
                        <AffiliateOfferUrl
                            zone={zone}
                            className="text-left lg:text-center py-3 rounded-lg"
                            offer={offer}
                            target="_blank"
                            style={{
                                color: "#0e5aa9",
                            }}
                        >
                            <span>View Deals</span>
                        </AffiliateOfferUrl>
                    )}
                </div>
            </div>
        </div>
    );
}
