import { Field, Image } from "@lib/shared/types";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function CalculatedYears({
    field,
    onChange,
    value,
    className,
}: {
    field: Field;
    onChange: (val: string) => void;
    value: string;
    className: string;
}): ReactElement {
    const checkIfSelectHasMinMaxValue = () => {
        if (
            field.maxValue?.value &&
            field.minValue?.value &&
            field.maxValue.type === "number" &&
            field.minValue.type === "number" &&
            field.maxValue.value > field.minValue.value
        ) {
            return true;
        }
        return false;
    };

    const gettingArrayFromMaxMinValues = (): {
        label: number;
        value: number;
        icon: null | Image;
    }[] => {
        const today = new Date();
        const year = today.getFullYear();
        const newArray = [];
        for (
            let i: number = parseInt(field.minValue?.value as string, 10);
            i <= parseInt(field.maxValue?.value as string, 10);
            i++
        ) {
            newArray.unshift({
                label: year - i,
                value: year - i,
                icon: null,
            });
        }
        if (field.meta?.reverseOptions === "true") {
            return newArray.reverse();
        }

        return newArray;
    };

    const renderOptions = () => {
        const options = checkIfSelectHasMinMaxValue()
            ? gettingArrayFromMaxMinValues()
            : field.defaultValues?.options;

        return (
            <>
                {options?.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </>
        );
    };

    return (
        <select
            className={`${styles["field"]} ${styles["select"]} ${
                !value ? styles["empty"] : ""
            } ${className ?? ""}`}
            onChange={(e) => onChange(e.target.value)}
            name={field.codeName}
            value={value}
            id={field.codeName}
        >
            {field.placeholder && <option value="">{field.placeholder}</option>}

            {renderOptions()}
        </select>
    );
}
