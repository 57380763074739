/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
    FormEvent,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
    TransitionEvent,
    useCallback,
} from "react";
import { Field, FieldMetaKeys, Step } from "@lib/shared/types";
import flattenDeep from "lodash/flattenDepth";
import pick from "lodash/pick";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import flatten from "lodash/flatten";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";

import {
    DomainForm,
    callEvent,
    getLookupsAndVariations,
    submitContactUsForm,
    submitLeadForm,
} from "src/api";
import {
    canShowFieldAfterDependency,
    fieldValidation,
    fullNameValidation,
    handleFieldMasking,
} from "@lib/shared/form";
import { getAccessToken } from "@lib/shared/cookies";
import { SubmitLeadFormResponse } from "src/api/submitLead";
import { useFormContext } from "./useFormContext";
import { useDomainContext } from "./useDomainContext";
import { usePrev } from "./usePrev";
import { differenceBetweenTwoObjects } from "@lib/sharedUtils";
import { getUserMeta } from "@lib/sharedUtils";
import { useRouter } from "next/router";
import { SHARED_FIELDS_CODENAME } from "@lib/shared/config";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
let leadId: null | string = null;

let isApiCallInProgress = false;
let startSendingData = false;
// @ts-ignore
global.submitStepClicked = false;

const queues: (() => Promise<void>)[] = [];
const skippedValidationFields: string[] = [];

export interface FormOtp {
    isRequired: boolean;
    isResolved: boolean;
    handleSubmit: (otp: string) => Promise<{ success: boolean }>;
    handleResend: () => Promise<{ success: boolean }>;
    handleScrollFormTop: () => void;
    handleEditPhoneNumber: (
        phoneNumber: string,
    ) => Promise<{ success: boolean }>;
    countDownTime: number;
}
export interface StreetAddress {
    streetNumber?: string;
    route?: string;
    toZipCode?: string;
    toCountry?: string;
    toCity?: string;
    toState?: string;
}
export interface ForceShowOffers {
    codeName: string;
    value: string;
    listSlug: string;
}
export interface FieldData {
    valid: boolean;
    errorMessage: string | null;
    value: string;
    optionSelectedIndex?: string | undefined;
    changed: boolean;
    fieldType: string;
    defaultValues: { options: { label: string; value: string }[] } | null;
}

export interface FieldsData {
    [x: string]: FieldData;
}

export interface ITextBlock {
    id: number;
    text: string;
    offerIds: number | null;
    categoryId: number;
    domainId: number;
    queryString: string | null;
    isActive: boolean;
    position: string;
    translations: [];
}
export interface UseFormHook {
    handleFieldChange: (
        field: Pick<Field, "mask" | "codeName" | "fieldType">,
        val: string,
    ) => void;

    handleFieldBlur: (field: Field) => void;
    handlePrevStep: () => void;
    handleSubmit: (
        ev: FormEvent<HTMLFormElement>,
        options?: { skipValidation?: boolean; isSecondForm?: boolean },
    ) => void;
    handleOutsideFormSubmit: () => void;
    currentStep: number;
    steps: Step[];
    visitedSteps: {};
    fieldsData: FieldsData;
    isLoading: boolean;
    successMessage: string;
    jornayaIdElement: JSX.Element;
    formError: null | string;
    formId: number;
    otp: FormOtp;
    formSubmitted: boolean;
    showExitModal: boolean;
    stepStatus: string;
    formErrors: number;
    formErrorsResolved: boolean;
    checkIfStepHasOneField: (fieldsData: FieldsData) => boolean;
    showRegulations: boolean;
    showCustomListingModal: boolean;
    setLoading: (isLoading: boolean) => void;
    setHasRegulations: (hasRegulations: boolean) => void;
    setShowCustomListingModal: (show: boolean) => void;
    textBlocks: ITextBlock[];
    showPiiCompletionOtherFields: boolean;
    setShowPiiCompletionOtherFields: (show: boolean) => void;
    showFormModal: boolean;
    toggleFormModal: (payload: boolean) => void;
    listingSlug: string | null;
    toggleShowFormInExitModal: (payload?: boolean) => void;
    handleCloseFormInsideExitModal: () => void;
    showFromInExitModal: boolean;
}

enum ActionTypes {
    SHOW_EXIT_MODAL = "SHOW_EXIT_MODAL",
    SET_STEP = "SET_STEP",
    SET_FIELDS_STATE = "SET_FIELDS_STATE",
    SET_LOADING_FORM = "SET_LOADING_FORM",
    SET_FORM_SUBMITTED = "SET_FORM_SUBMITTED",
    SET_FORM_ERROR_FIELD = "SET_FORM_ERROR_FIELD",
    SET_FORM_ERROR = "SET_FORM_ERROR",
    SET_NEXT_STEP = "SET_NEXT_STEP",
    SET_OTP = "SET_OTP",
    SET_FORM_WAITING_OTP = "SET_FORM_WAITING_OTP",
    SET_STEP_STATUS = "SET_STEP_STATUS",
    RESET_FORM_SUBMIT = "RESET_FORM_SUBMIT",
    SHOW_REGULATIONS_PAGE = "SHOW_REGULATIONS_PAGE",
    SHOW_CUSTOM_LISTING_MODAL = "SHOW_CUSTOM_LISTING_MODAL",
    SET_FORM_MODAL = "SET_FORM_MODAL",
    TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL = "TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL",
}

interface InitState {
    waitingOtp: boolean;
    submitted: boolean;
    loadingForm: boolean;
    currentStep: number;
    visitedSteps: { [x: number]: number };
    fieldsState: FieldsData;
    formError: string | null;
    formErrors: number;
    formErrorsResolved: boolean;
    otp: {
        isRequired: boolean;
        isResolved: boolean;
        countDown: number;
    };
    showExitModal: boolean;
    stepStatus: "success" | "error";
    blockBack: boolean;
    showRegulations: boolean;
    showCustomListingModal: boolean;
    showFormModal: boolean;
    showFromInExitModal: boolean;
}

const initState: InitState = {
    waitingOtp: false,
    submitted: false,
    loadingForm: false,
    currentStep: 0,
    fieldsState: {},
    formError: null,
    formErrors: 0,
    formErrorsResolved: false,
    stepStatus: "success",
    visitedSteps: {},
    otp: {
        isRequired: false,
        isResolved: false,
        countDown: 60,
    },
    showExitModal: false,
    blockBack: false,
    showRegulations: false,
    showCustomListingModal: false,
    showFormModal: false,
    showFromInExitModal: false,
};

type FormReducerAction =
    | {
          type: ActionTypes.SET_FORM_ERROR;
          payload: string | null;
      }
    | {
          type: ActionTypes.SET_FORM_SUBMITTED;
          payload: { formId: number; isOtpResolved?: boolean };
      }
    | { type: ActionTypes.SET_STEP; payload: number }
    | { type: ActionTypes.SHOW_EXIT_MODAL; payload: number }
    | { type: ActionTypes.SET_FORM_ERROR; payload: string | null }
    | {
          type: ActionTypes.SET_FIELDS_STATE;
          payload: FieldsData | null;
      }
    | { type: ActionTypes.SET_LOADING_FORM; payload: boolean }
    | {
          type: ActionTypes.SET_FORM_ERROR_FIELD;
          payload: {
              //   step: number;
              formErrors: number;
              fields: FieldsData | null;
          };
      }
    | { type: ActionTypes.SET_NEXT_STEP; payload: undefined }
    | {
          type: ActionTypes.SET_OTP;
          payload: {
              isRequired: true;
              isResolved: boolean;
              countDown: number;
          };
      }
    | { type: ActionTypes.SET_FORM_WAITING_OTP; payload: boolean }
    | {
          type: ActionTypes.SET_STEP_STATUS;
          payload: boolean;
      }
    | { type: ActionTypes.RESET_FORM_SUBMIT }
    | { type: ActionTypes.SET_FORM_MODAL; payload: boolean }
    | {
          type: ActionTypes.TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL;
          payload: boolean | undefined;
      }
    | {
          type: ActionTypes.SHOW_REGULATIONS_PAGE;
          payload: {
              showRegulations: boolean;
          };
      }
    | {
          type: ActionTypes.SHOW_CUSTOM_LISTING_MODAL;
          payload: {
              showCustomListingModal: boolean;
          };
      };

export const formReducer = (
    state: InitState,
    action: FormReducerAction,
): InitState => {
    switch (action.type) {
        case ActionTypes.SET_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };
        case ActionTypes.SHOW_EXIT_MODAL:
            return {
                ...state,
                showExitModal: !!action.payload,
            };
        case ActionTypes.TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL:
            return {
                ...state,
                showFromInExitModal:
                    action.payload ?? !state.showFromInExitModal,
            };
        case ActionTypes.SET_FIELDS_STATE:
            return {
                ...state,
                fieldsState: { ...state.fieldsState, ...action.payload },
            };
        case ActionTypes.SET_LOADING_FORM:
            return {
                ...state,
                loadingForm: action.payload,
            };
        case ActionTypes.SET_FORM_SUBMITTED: {
            sessionStorage.removeItem(`form-${action.payload.formId}`);
            sessionStorage.removeItem(`lead-id-form-${action.payload.formId}`);
            const isResolved = action.payload.isOtpResolved ?? false;
            leadId = null;
            startSendingData = false;

            return {
                ...state,
                submitted: true,
                waitingOtp: false,
                formErrors: 0,
                otp: {
                    ...state.otp,
                    isRequired: false,
                    isResolved,
                },
            };
        }
        case ActionTypes.SET_STEP_STATUS:
            return {
                ...state,
                stepStatus: action.payload ? "success" : "error",
            };

        case ActionTypes.SET_FORM_ERROR_FIELD: {
            window.history.pushState(
                null,
                "",
                `#step-${state.currentStep + 2}`,
            );

            return {
                ...state,
                // currentStep: action.payload.step,
                fieldsState: action.payload.fields!,
                formErrors: action.payload.formErrors,
                formErrorsResolved: true,
                blockBack: true,
            };
        }
        case ActionTypes.RESET_FORM_SUBMIT:
            return {
                ...state,
                formErrors: 0,
                formErrorsResolved: false,
            };
        case ActionTypes.SET_FORM_ERROR:
            return {
                ...state,
                formError: action.payload,
            };
        case ActionTypes.SET_FORM_MODAL:
            return {
                ...state,
                showFormModal: action.payload,
            };
        case ActionTypes.SET_NEXT_STEP:
            window.history.pushState(
                null,
                "",
                `#step-${state.currentStep + 2}`,
            );
            return {
                ...state,
                currentStep: state.currentStep + 1,
                loadingForm: false,
                visitedSteps: {
                    ...state.visitedSteps,
                    [state.currentStep]: state.currentStep,
                },
            };

        case ActionTypes.SET_OTP:
            return {
                ...state,
                otp: action.payload,
                formErrors: 0,
            };
        case ActionTypes.SET_FORM_WAITING_OTP:
            return {
                ...state,
                waitingOtp: action.payload,
            };
        case ActionTypes.SHOW_REGULATIONS_PAGE:
            return {
                ...state,
                showRegulations: action.payload.showRegulations,
            };
        case ActionTypes.SHOW_CUSTOM_LISTING_MODAL:
            return {
                ...state,
                showCustomListingModal: action.payload.showCustomListingModal,
            };
        default:
            return state;
    }
};

export function useNext(value: InitState): () => Promise<InitState> {
    const valueRef = useRef(value);
    const resolvesRef = useRef<((value: InitState) => void)[]>([]);

    useEffect(() => {
        if (valueRef.current !== value) {
            for (const resolve of resolvesRef.current) {
                resolve(value);
            }
            resolvesRef.current = [];
            valueRef.current = value;
        }
    }, [value]);

    return () =>
        new Promise((resolve) => {
            resolvesRef.current = [...resolvesRef.current, resolve];
        });
}
let clickedOnSubmit: boolean = false;
export const useForm = (
    form: DomainForm,
    isSecondServiceForm: boolean | undefined,
    isPiiCompletionForm: boolean | undefined,
    goToListPage: boolean | undefined,
    resetLeadId: boolean,
    scrollToTop: boolean,
    showInModal: boolean,
    isFormInsideOffersModal: boolean,
): UseFormHook => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [listingSlug, setListingSlug] = useState<string | null>(null);
    const { asPath, query } = useRouter();
    const { fields } = useMemo(() => {
        return useFields(form);
    }, [form]);
    const [showPiiCompletionOtherFields, setShowPiiCompletionOtherFields] =
        useState(false);
    const [state, dispatch] = useReducer(formReducer, {
        ...initState,
        fieldsState: fields,
    });
    const { steps, setSteps } = useSteps(form, fields);
    useEffect(() => {
        if (isSecondServiceForm && fields) {
            const updatedSteps = [...form.steps];
            setSteps(updatedSteps);
        }
    }, [form, isSecondServiceForm, fields]);

    useEffect(() => {
        // clear leadId on multiform service
        if (state.currentStep === 0 && resetLeadId) {
            const cachedLeadID = sessionStorage.getItem(
                `lead-id-form-${form.id}`,
            );
            leadId = cachedLeadID ?? null;
            startSendingData = false;
        }
        if (state.submitted && state.currentStep === form.steps.length - 1) {
            setFormSubmitted(true);
        }
    }, [state.submitted, state.currentStep, form]);

    const prevFieldsState = usePrev(state.fieldsState);

    const nextState = useNext(state);
    const [windowStep, setWindowStep] = useState<number | null>(null); // window history step
    const successMessage = useSuccessStep(
        form.successMessage,
        state.fieldsState,
        !!isSecondServiceForm,
    );
    const jornayaIdRef = useRef(null);

    const { setFormSubmitted } = useFormContext();

    const {
        locale,
        selectedCountryCode,
        totalTimeSpendOnSite,
        domain,
        category,
        piiCompletionData,
        // setShowFormModalState,
    } = useDomainContext();

    const jornayaIdElement = useMemo(() => {
        return (
            <input
                id="leadid_token"
                name="universal_leadid"
                type="hidden"
                value=""
                ref={jornayaIdRef}
            />
        );
    }, []);

    const {
        submitted,
        loadingForm,
        currentStep,
        fieldsState,
        formError,
        otp,
        waitingOtp,
        visitedSteps,
        showExitModal,
        stepStatus,
        formErrors,
        formErrorsResolved,
        showRegulations,
        showCustomListingModal,
        showFormModal,
        showFromInExitModal,
    } = state;

    const sendDataToServerListener = useCallback(() => {
        const accessToken = getAccessToken();
        if (!clickedOnSubmit && accessToken) {
            void sendDataToServer({ submit: false });
        }
    }, []);

    const getStepField = (fieldCodeName: string) => {
        const stepIndex = steps.findIndex((s) =>
            s.fields.find((f) => f.codeName === fieldCodeName),
        );

        return steps[stepIndex].fields.find(
            (f) => f.codeName === fieldCodeName,
        );
    };

    const toggleShowFormInExitModal = (payload?: boolean) => {
        dispatch({
            type: ActionTypes.TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL,
            payload: payload,
        });
    };

    const sendDataToServer = async ({
        submit,
        formOtp,
        skippedValidationFields,
        secondForm,
        consent,
    }: {
        submit: boolean;
        formOtp?: string;
        skippedValidationFields?: string[];
        secondForm?: boolean;
        consent?: boolean;
    }): Promise<SubmitLeadFormResponse> => {
        const leadCaptureForm = domain.config?.leadCapture;
        if (
            !startSendingData ||
            (leadCaptureForm && !submit) ||
            (goToListPage && !submit)
        )
            return { success: false, data: null };

        if (submit) {
            clickedOnSubmit = true;
        }
        isApiCallInProgress = true;
        dispatch({
            type: ActionTypes.SET_FORM_ERROR,
            payload: null,
        });

        const { fieldsState: newFieldsState } = await nextState();

        const fieldsThatHaveValue: { codeName: string; value: string }[] = [];

        if (isSecondServiceForm) {
            form.steps[0].fields.forEach((f) => {
                if (newFieldsState[f.codeName].value.trim()) {
                    fieldsThatHaveValue.push({
                        codeName: f.codeName,
                        value: newFieldsState[f.codeName].value,
                    });
                }
            });
        } else {
            const leadCaptureAllowedFields = [
                "checkbox",
                "radio",
                "select",
                "multiSelect",
            ];
            Object.keys(newFieldsState).forEach((codeName) => {
                if (newFieldsState[codeName].value.trim()) {
                    const leadCaptureFinalValue =
                        newFieldsState[codeName].value.split(",");
                    fieldsThatHaveValue.push({
                        codeName,
                        value:
                            leadCaptureAllowedFields.includes(
                                newFieldsState[codeName].fieldType,
                            ) && leadCaptureForm
                                ? newFieldsState[
                                      codeName
                                  ].defaultValues?.options
                                      .filter((el) =>
                                          leadCaptureFinalValue.includes(
                                              el.value,
                                          ),
                                      )
                                      .map((el) => el.label)
                                      .join(",")
                                : newFieldsState[codeName].value,
                    });
                }
            });

            if (state.submitted) {
                return {
                    success: false,
                    data: null,
                };
            }
        }

        if (fieldsThatHaveValue.length) {
            if (submit && leadCaptureForm) {
                const token = executeRecaptcha
                    ? await executeRecaptcha("contact_us_form")
                    : null;

                const mainFields = [
                    "email",
                    "firstName",
                    "lastName",
                    "phoneNumber",
                ];
                const finalData = {
                    email: fieldsThatHaveValue.find(
                        (el) => el.codeName === "email",
                    )?.value,
                    firstName: fieldsThatHaveValue.find(
                        (el) => el.codeName === "firstName",
                    )?.value,
                    googleReCaptcha: token,
                    lastName: fieldsThatHaveValue.find(
                        (el) => el.codeName === "lastName",
                    )?.value,
                    phone: fieldsThatHaveValue.find(
                        (el) => el.codeName === "phoneNumber",
                    )?.value,
                    type: "partnerWithUs",
                    customFields: [
                        { key: "category", value: category?.name },
                        ...fieldsThatHaveValue
                            .filter((el) => !mainFields.includes(el.codeName))
                            .map((el) => ({
                                key: el.codeName,
                                value: el.value,
                            })),
                    ],
                };

                const { error } = await submitContactUsForm({
                    //@ts-ignore
                    fields: { ...finalData },
                });

                return { success: error ? false : true, data: null };
            }
            if (submit && goToListPage) {
                const gettingFilters = () => {
                    const filters = fieldsThatHaveValue.reduce((acc, item) => {
                        if (item.codeName === "topClinicalTrialsAgeField") {
                            const isTheAge18plus =
                                item.value === "topClinicalTrialsAgeField_18+";
                            acc["age"] = isTheAge18plus
                                ? "18-100"
                                : item.value.split("_")[1];
                            return acc;
                        }
                        if (item.codeName === "topClinicalTrialsGenderField") {
                            acc["gender"] = item.value.split("_")[1];
                            return acc;
                        }
                        acc[item.codeName] = item.value;
                        return acc;
                    }, {});
                    return btoa(JSON.stringify(filters));
                };

                const zipCode = fieldsThatHaveValue.find(
                    (el) => el.codeName === "zipCode",
                )?.value;

                const formattedZipCode =
                    (zipCode as string).length === 4
                        ? `0${zipCode as string}`
                        : zipCode;

                window.location.href = `${window.location.origin}/offers?zc=${
                    formattedZipCode as string
                }&filters=${gettingFilters()}&referrer=${
                    category?.slugAlias ?? ""
                }&${gettingQueriesAsString(query, "inline", ["category"])}`;

                return { success: true, data: null };
            }

            const meta = getUserMeta();
            const sharedMeta = {
                countryCode: selectedCountryCode ? selectedCountryCode : "US",
                skippedValidationFields:
                    skippedValidationFields && skippedValidationFields?.length
                        ? skippedValidationFields.join(",")
                        : "",
                timeOnSite: totalTimeSpendOnSite.current / 1000,
                lander: localStorage.getItem("lander") ?? "",
                consent,
            };

            const isSecondService = domain.settings?.isSecondServiceEnabled;
            const currentLeadId = sessionStorage.getItem(
                `lead-id-form-${form.id}`,
            );
            const { error, data } = await submitLeadForm({
                fields: fieldsThatHaveValue,
                leadId: piiCompletionData?.leadId ?? leadId ?? currentLeadId,
                jornayaId:
                    (
                        document?.getElementById(
                            "leadid_token",
                        ) as HTMLInputElement | null
                    )?.value ?? undefined,
                url: window.location.href,
                submit,
                domainFormId: form?.domainFormId,
                otpCode: formOtp ?? undefined,
                language: locale ?? "en",
                meta: {
                    ...meta,
                    ...(isSecondService && secondForm
                        ? {
                              isSecondService: true,
                              ...(localStorage.getItem("mainId")
                                  ? {
                                        parent:
                                            localStorage.getItem("mainId") ??
                                            "",
                                    }
                                  : {}),

                              startingService: category?.name || "",
                          }
                        : {}),
                    ...sharedMeta,
                },
                isPiiCompletionForm: isPiiCompletionForm || undefined,
                token: isPiiCompletionForm
                    ? (query["pii-token"] as string)
                    : undefined,
            });

            leadId = data?.leadId ?? leadId ?? null;
            if (leadId && resetLeadId) {
                sessionStorage.setItem(`lead-id-form-${form.id}`, leadId);
            }

            if (!isSecondServiceForm) {
                localStorage.setItem("mainId", leadId as string);
            }
            isApiCallInProgress = false;

            if (queues.length > 0) {
                const queue = queues.shift();
                if (queue) await queue();
            }

            return { data, success: error ? false : true };
        }

        return {
            success: false,
            data: null,
        };
    };
    const setLoading = (loading: boolean) => {
        dispatch({ type: ActionTypes.SET_LOADING_FORM, payload: loading });
    };
    const setHasRegulations = (hasRegulations: boolean) => {
        dispatch({
            type: ActionTypes.SHOW_REGULATIONS_PAGE,
            payload: { showRegulations: hasRegulations },
        });
    };
    const setShowCustomListingModal = (show: boolean) => {
        dispatch({
            type: ActionTypes.SHOW_CUSTOM_LISTING_MODAL,
            payload: { showCustomListingModal: show },
        });
    };
    const addFieldToSkippedValidation = (codeName: string) => {
        if (!skippedValidationFields.includes(codeName)) {
            skippedValidationFields.push(codeName);
        }
    };

    const serverSideValidation = ({
        data,
        success,
    }: SubmitLeadFormResponse) => {
        dispatch({ type: ActionTypes.SET_LOADING_FORM, payload: false });
        if (success) {
            if (data?.otpRequired) {
                dispatch({
                    type: ActionTypes.SET_OTP,
                    payload: {
                        ...otp,
                        isRequired: true,
                        countDown: data.otpTime ?? 60,
                    },
                });
                dispatch({
                    type: ActionTypes.SET_FORM_WAITING_OTP,
                    payload: true,
                });
            } else {
                dispatch({
                    type: ActionTypes.SET_FORM_SUBMITTED,
                    payload: { formId: form.id },
                });
            }
        } else if (data?.results) {
            const newFieldsState = { ...fieldsState };
            // validate all fields
            // validateAllFields(newFieldsState);

            data?.results?.map((result) => {
                const field = getStepField(result.codeName);

                newFieldsState[result.codeName] = {
                    ...fieldsState[result.codeName],
                    valid: false,
                    errorMessage: result.reason ?? field?.errorMessage,
                };
            });

            dispatch({
                type: ActionTypes.SET_FORM_ERROR_FIELD,
                payload: { fields: newFieldsState, formErrors: formErrors + 1 },
            });
        } else {
            dispatch({
                type: ActionTypes.SET_FORM_ERROR,
                payload:
                    form.errorMessage ??
                    "An error has occurred, please try again",
            });
        }
    };

    const saveSharedFields = (newFieldsState: FieldsData) => {
        const SHARED_FIELDS: { [x: string]: string } = {};
        Object.keys(newFieldsState).forEach((fieldElement) => {
            if (SHARED_FIELDS_CODENAME.includes(fieldElement)) {
                SHARED_FIELDS[fieldElement] =
                    newFieldsState[fieldElement].value;
            }
        });
        localStorage.setItem("sharedFields", JSON.stringify(SHARED_FIELDS));
    };

    const handleFieldValidation = async (
        field: Field,
        checkRegulations: boolean = true,
    ) => {
        const validCountryCode =
            form?.supportedCountries.length &&
            form?.supportedCountries.find(
                (item) => item.value === selectedCountryCode,
            )
                ? (selectedCountryCode as string)
                : "US";
        const { valid, message, newValue, hasRegulations, validationSkipped } =
            await fieldValidation(
                field,
                fieldsState[field.codeName].value.trim(),
                locale,
                validCountryCode,
            );

        if (validationSkipped) {
            addFieldToSkippedValidation(field.codeName);
        }

        if (hasRegulations && checkRegulations) {
            setHasRegulations(true);
            return;
        }

        const newObj: FieldsData = {};
        newObj[field.codeName] = {
            ...fieldsState[field.codeName],
            valid,
            errorMessage: !valid
                ? field.errorMessage
                    ? field.errorMessage
                    : message
                : "",
            value: newValue ?? fieldsState[field.codeName]!.value,
        };

        dispatch({
            type: ActionTypes.SET_FIELDS_STATE,
            payload: { ...newObj },
        });
        const { fieldsState: newFieldsState } = await nextState();
        saveSharedFields(newFieldsState);

        return valid;
    };

    const handleFieldBlur = async (field: Field) => {
        if (!fieldsState[field.codeName].changed) return;
        // @ts-ignore
        if (global.submitStepClicked) return;
        void handleFieldValidation(field, false);

        if (startSendingData && !loadingForm) {
            if (!isApiCallInProgress) {
                void sendDataToServer({
                    submit: false,
                    skippedValidationFields,
                });
            } else {
                queues.push(async () => {
                    void sendDataToServer({
                        submit: false,
                        skippedValidationFields,
                    });
                });
            }
        }
    };

    const handleStepValidation = async (): Promise<boolean> => {
        let stepValid = true;
        let fields = [];

        if (formErrors) {
            fields = flattenDeep(steps.map((step) => step.fields)).filter(
                (field) => !fieldsState[field.codeName].valid,
            );
        } else {
            fields = steps[currentStep].fields;
        }

        dispatch({ type: ActionTypes.SET_LOADING_FORM, payload: true });

        for (const field of fields) {
            const fieldValid = !canShowFieldAfterDependency(field, fieldsState)
                ? true
                : await handleFieldValidation(field);

            if (!fieldValid) {
                if (
                    isPiiCompletionForm &&
                    stepValid &&
                    !piiCompletionData?.missingFields.includes(field.codeName)
                ) {
                    setShowPiiCompletionOtherFields(true);
                    const { codeName } = field;
                    setTimeout(() => {
                        const element = document.getElementById(codeName);
                        if (element) {
                            element.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    }, 100);
                }

                stepValid = false;
            }
        }

        if (
            stepValid &&
            steps[currentStep].fields.every((f) =>
                ["firstName", "lastName"].includes(f.codeName),
            )
        ) {
            const { valid: fullNameValid, message: fullNameMessage } =
                await fullNameValidation(
                    pick(mapValues(fieldsState, "value"), [
                        "firstName",
                        "lastName",
                    ]),
                );

            const newObj: FieldsData = {};

            dispatch({ type: ActionTypes.SET_LOADING_FORM, payload: false });

            const { fieldsState: newFieldsState } = await nextState();

            newObj["firstName"] = {
                ...newFieldsState["firstName"],
                valid: fullNameValid,
                errorMessage: fullNameMessage,
            };
            newObj["lastName"] = {
                ...newFieldsState["lastName"],
                valid: fullNameValid,
                errorMessage: fullNameMessage,
            };

            dispatch({
                type: ActionTypes.SET_FIELDS_STATE,
                payload: { ...newObj },
            });

            if (!fullNameValid) {
                stepValid = false;
            }
        }

        dispatch({
            type: ActionTypes.SET_LOADING_FORM,
            payload: false,
        });
        dispatch({ type: ActionTypes.SET_STEP_STATUS, payload: stepValid });
        return stepValid;
    };

    const startLoadingForm = () => {
        dispatch({
            type: ActionTypes.SET_LOADING_FORM,
            payload: true,
        });
    };

    const stopLoadingForm = () => {
        dispatch({
            type: ActionTypes.SET_LOADING_FORM,
            payload: false,
        });
    };

    const toggleFormModal = (payload: boolean) => {
        if (payload) {
            const backdrop = document.getElementById("backdrop");

            if (!backdrop) {
                const backdrop = document.createElement("div");
                backdrop.id = "backdrop";
                backdrop.classList.add("backdrop");
                document.body.appendChild(backdrop);
                document
                    .getElementsByTagName("html")[0]
                    .classList.add("overflow-hidden");
                document.body.classList.add("overflow-hidden");
            }
        } else {
            const backdrop = document.getElementById("backdrop");

            document
                .getElementsByTagName("html")[0]
                .classList.remove("overflow-hidden");
            document.body.classList.remove("overflow-hidden");

            if (backdrop) {
                backdrop.remove();
            }
            dispatch({
                type: ActionTypes.SET_STEP,
                payload: 0,
            });
        }
        dispatch({
            type: ActionTypes.SET_FORM_MODAL,
            payload,
        });
    };

    const handleSubmit = async (
        ev?: FormEvent<HTMLFormElement>,
        options?: { skipValidation?: boolean; isSecondForm?: boolean },
    ) => {
        ev?.preventDefault();
        if (loadingForm || submitted || waitingOtp) return;
        // @ts-ignore
        global.submitStepClicked = true;

        const stepValid = options?.skipValidation
            ? false
            : await handleStepValidation();

        if (currentStep === 0) {
            void callEvent({
                event: "FirstFormInteraction",
            });
        }

        if (currentStep === steps.length - 1) {
            void sendDataToServer({
                submit: false,
                consent: true,
                secondForm: options?.isSecondForm,
            });
        }

        if (stepValid || options?.skipValidation) {
            const isSubmit = currentStep === steps.length - 1;

            const getLeadId = async () => {
                const { data, success } = await sendDataToServer({
                    submit: isSubmit,
                    skippedValidationFields,
                    secondForm: options?.isSecondForm ? true : false,
                });

                if (isSubmit) {
                    serverSideValidation({ data, success });
                } else {
                    dispatch({
                        type: ActionTypes.SET_NEXT_STEP,
                        payload: undefined,
                    });
                }
            };
            // the below zipCode is saved from street address field
            localStorage.removeItem("zipCode");
            if (leadId) {
                if (isApiCallInProgress) {
                    // if there is another api in progress, push step data insertion to the queue
                    if (isSubmit) {
                        startLoadingForm();
                        queues.push(async () => {
                            const { data, success } = await sendDataToServer({
                                submit: isSubmit,
                                skippedValidationFields,
                                secondForm: options?.isSecondForm
                                    ? true
                                    : false,
                            });
                            serverSideValidation({ data, success });
                        });
                    } else {
                        queues.push(async () => {
                            void sendDataToServer({
                                submit: isSubmit,
                                skippedValidationFields,
                                secondForm: options?.isSecondForm
                                    ? true
                                    : false,
                            });
                        });
                        dispatch({
                            type: ActionTypes.SET_NEXT_STEP,
                            payload: undefined,
                        });
                    }
                } else {
                    if (isSubmit) {
                        startLoadingForm();
                        const { data, success } = await sendDataToServer({
                            submit: isSubmit,
                            skippedValidationFields,
                            secondForm: options?.isSecondForm ? true : false,
                        });

                        serverSideValidation({ data, success });
                    } else {
                        void sendDataToServer({
                            submit: isSubmit,
                            skippedValidationFields,
                            secondForm: options?.isSecondForm ? true : false,
                        });
                        dispatch({
                            type: ActionTypes.SET_NEXT_STEP,
                            payload: undefined,
                        });
                    }
                }
            } else {
                startLoadingForm();
                if (isApiCallInProgress) {
                    // if there is another api in progress, push step data insertion to the queue
                    queues.push(async () => {
                        void getLeadId();
                    });
                } else {
                    void getLeadId();
                }
            }
        }
    };
    const loadFieldsFromSessionStorage = (): FieldsData | null => {
        try {
            if (isPiiCompletionForm) {
                const fields =
                    piiCompletionData?.fields.reduce((acc, field) => {
                        acc[field.codeName] = {
                            value: field.value,
                            errorMessage: "",
                            changed: false,
                            valid: true,
                        };
                        return acc;
                    }, {} as FieldsData) ?? null;
                return fields;
            }
            const stateStr = sessionStorage.getItem(`form-${form.id}`);

            return stateStr
                ? (JSON.parse(stateStr) as FieldsData | null)
                : null;
        } catch (e) {
            return null;
        }
    };

    const getFieldsDataDependency = (
        {
            checkChangedFields,
            changedFields,
        }: {
            checkChangedFields?: boolean;
            changedFields: FieldsData;
        } = { checkChangedFields: true, changedFields: {} },
    ) => {
        const allFields = flatten(steps.map((step) => step.fields));
        const changedFieldsState = differenceBetweenTwoObjects(
            prevFieldsState,
            { ...fieldsState, ...changedFields },
        );

        const fieldsWithDataDependencyAndDependentOn = allFields.filter(
            (f) =>
                f.dataDependency &&
                f.dataDependency.dependency &&
                f.dataDependency?.dependency?.some((dependencyItem) =>
                    checkChangedFields
                        ? !!changedFieldsState[dependencyItem.fieldCodeName]
                        : true,
                ),
        );

        return {
            fieldsWithDataDependencyAndDependentOn,
        };
    };

    const fetchFieldsDataDependency = async (
        fieldsWithDataDependencyAndDependentOn: Field[],
    ) => {
        const { fieldsState: newFieldsState } = await nextState();
        for await (const field of fieldsWithDataDependencyAndDependentOn) {
            const dependentOnFields: {
                dependentOnType: "lookups" | "variations";
                id: number | undefined;
            }[] = [];
            const tempSteps = [...steps];
            let fieldIndex = 0;

            //Get step index for the field
            const stepIndex = steps.findIndex((step) =>
                step.fields.some((f, index) => {
                    if (f.codeName === field.codeName) {
                        fieldIndex = index;
                        return true;
                    }
                    return false;
                }),
            );

            // Fetch fields data if its depend on another field
            if (
                field.dataDependency?.dependency?.every((dependencyItem) => {
                    const allFields = flatten(steps.map((step) => step.fields));
                    const dependentOnField = allFields.find(
                        (f) => f.codeName === dependencyItem.fieldCodeName,
                    );

                    const dependentUponFieldData =
                        newFieldsState[dependentOnField.codeName];

                    dependentOnFields.push({
                        dependentOnType: dependencyItem.dependentOn,
                        id: dependentOnField?.defaultValues?.options.find(
                            (options) =>
                                options.value === dependentUponFieldData.value,
                        )?.id,
                    });

                    return (
                        dependentUponFieldData.value &&
                        dependentUponFieldData.valid
                    );
                })
            ) {
                const { ordering, type, source } = field.dataDependency;
                const variationsIds = dependentOnFields.filter(
                    (i) => i.dependentOnType === "variations",
                );

                // should fire loading for field and clear all option before getting the response
                const updatedSteps = [...steps];
                updatedSteps[stepIndex].fields[fieldIndex] = {
                    ...updatedSteps[stepIndex].fields[fieldIndex],
                    fetchingFieldData: true,
                };
                updatedSteps[stepIndex].fields[fieldIndex].defaultValues = {
                    options: [],
                };
                setSteps(updatedSteps);

                const { data, error } = await getLookupsAndVariations({
                    source,
                    queries: {
                        orderBy: ordering?.field,
                        orderByDirection: ordering?.direction,
                        type,
                        lookupsId: dependentOnFields.find(
                            (i) => i.dependentOnType === "lookups",
                        )?.id,
                        variationsIds: variationsIds.length
                            ? variationsIds.map((i) => i.id).join(",")
                            : undefined,
                    },
                });

                if (data && !error) {
                    // reset fetchingFieldData to false to hide loading inside select
                    tempSteps[stepIndex].fields[fieldIndex] = {
                        ...tempSteps[stepIndex].fields[fieldIndex],
                        fetchingFieldData: false,
                    };
                    tempSteps[stepIndex].fields[fieldIndex].defaultValues = {
                        options: data,
                    };
                    setSteps(tempSteps);
                }
            } else {
                // reset fetchingFieldData to false to hide loading inside select
                tempSteps[stepIndex].fields[fieldIndex] = {
                    ...tempSteps[stepIndex].fields[fieldIndex],
                    fetchingFieldData: false,
                };
                tempSteps[stepIndex].fields[fieldIndex].defaultValues = {
                    options: [],
                };

                setSteps(tempSteps);
            }
        } // end for
    };

    const forceShowListingModal = ({
        meta,
        selectedValue,
        currentFieldCodeName,
    }: {
        meta: null | { [key in FieldMetaKeys]: string };
        selectedValue: string;
        currentFieldCodeName: string;
    }) => {
        if (meta && meta?.forceShowOffers) {
            const parsedMeta = JSON.parse(
                meta?.forceShowOffers,
            ) as ForceShowOffers;
            if (
                parsedMeta.value === selectedValue &&
                parsedMeta.codeName === currentFieldCodeName
            ) {
                dispatch({
                    type: ActionTypes.SHOW_CUSTOM_LISTING_MODAL,
                    payload: { showCustomListingModal: true },
                });
                setListingSlug(parsedMeta.listSlug);
                return true;
            }
        }
        return false;
    };

    const handleFieldChange = async (
        field: Field,
        val: string,
        optionSelectedIndex: string | undefined,
    ) => {
        // @ts-ignore
        global.submitStepClicked = false;
        let newVal = val;

        if (field.mask) {
            if (field.mask === "zipCode" && selectedCountryCode === "CA") {
                newVal = handleFieldMasking("postalCode", val);
            } else {
                newVal = handleFieldMasking(field.mask, val);
            }
        }

        const newObj: FieldsData = {};

        // Update field value
        newObj[field.codeName] = {
            ...field,
            valid: true,
            errorMessage: "",
            value: newVal,
            changed: true,
        };

        if (optionSelectedIndex) {
            newObj[field.codeName]["optionSelectedIndex"] = optionSelectedIndex;
        }

        const { fieldsWithDataDependencyAndDependentOn } =
            getFieldsDataDependency({
                changedFields: newObj,
                checkChangedFields: true,
            });

        // Get all fields that depends on the field that value changed
        const finalChangedFields = [...fieldsWithDataDependencyAndDependentOn];

        // Get all fields that depends on the depends field that value changed
        fieldsWithDataDependencyAndDependentOn.forEach(
            (fieldWithDataDependency) => {
                const { fieldsWithDataDependencyAndDependentOn: temp } =
                    getFieldsDataDependency({
                        checkChangedFields: true,
                        // reset value to empty string
                        changedFields: {
                            [fieldWithDataDependency.codeName]: {
                                value: "",
                            },
                        },
                    });

                newObj[fieldWithDataDependency.codeName] = {
                    ...fieldWithDataDependency,
                    valid: true,
                    errorMessage: "",
                    value: "",
                    changed: true,
                };

                temp.forEach((i) => {
                    newObj[i.codeName] = {
                        ...i,
                        valid: true,
                        errorMessage: "",
                        value: "",
                        changed: true,
                    };
                });

                finalChangedFields.push(...temp);
            },
        );

        // update state for all dependingOn fields
        dispatch({
            type: ActionTypes.SET_FIELDS_STATE,
            payload: { ...newObj },
        });

        void fetchFieldsDataDependency(uniqBy(finalChangedFields, "codeName"));

        const { fieldsState: newFieldsState } = await nextState();

        sessionStorage.setItem(
            `form-${form.id}`,
            JSON.stringify({ ...newFieldsState, ...newObj }),
        );

        if (
            field.fieldType === "radio" &&
            !forceShowListingModal({
                meta: field.meta,
                selectedValue: val,
                currentFieldCodeName: field.codeName,
            })
        ) {
            if (checkIfStepHasOneField(newObj)) {
                void handleSubmit(undefined, { skipValidation: true });
            }
        }
    };

    const checkIfStepHasOneField = (fieldsData: FieldsData) => {
        if (
            steps[currentStep]?.fields.length === 1 &&
            steps[currentStep]?.fields[0].fieldType === "radio"
        ) {
            return true;
        }

        let numberOfFields = 0;
        let stepHasOneRadioField = false;

        steps[currentStep].fields.forEach((field) => {
            if (canShowFieldAfterDependency(field, fieldsData)) {
                numberOfFields++;
                stepHasOneRadioField =
                    field.fieldType === "radio" && !stepHasOneRadioField
                        ? true
                        : false;
            }
        });

        return numberOfFields === 1 && stepHasOneRadioField;
    };

    const handlePrevStep = () => {
        dispatch({
            type: ActionTypes.SET_STEP,
            payload: currentStep - 1,
        });
        window.history.back();
    };

    const handleWindowBack = () => {
        if (!otp.isResolved && window.location.hash.startsWith("#step-")) {
            const step = parseInt(
                window.location.hash.replace("#step-", ""),
                10,
            );
            setWindowStep(step);
        } else {
            window.history.pushState("", document.title, `#step-1`);
            dispatch({
                type: ActionTypes.SHOW_EXIT_MODAL,
                payload: 1,
            });
        }
    };

    const handleFirstStepFieldFocus = () => {
        const selector =
            "input[type=text], input[type=tel], input[type=number], input[type=date],input[type=email], textarea";

        if (
            document
                .getElementById(`form_${form.id}`)
                ?.querySelectorAll(selector).length
        ) {
            setTimeout(() => {
                const element = document
                    .getElementById(`form_${form.id}`)
                    ?.querySelectorAll(selector);
                if (element && element.length) {
                    (element[0] as HTMLInputElement).focus();
                }
            }, 300);
        }
    };

    const handleOutsideFormSubmit = () => {
        dispatch({
            type: ActionTypes.SET_FORM_SUBMITTED,
            payload: { formId: form.id },
        });
    };

    const handleScrollFormTop = () => {
        try {
            window.scroll({
                behavior: "smooth",
                top: 0,
            });
        } catch (err) {
            if (err instanceof TypeError) {
                window.scroll(0, 0);
            } else {
                throw err;
            }
        }
    };

    const handleOtpSubmit = async (
        formOtp: string,
    ): Promise<{ success: boolean }> => {
        startLoadingForm();

        const { success } = await sendDataToServer({
            submit: true,
            formOtp,
        });

        stopLoadingForm();

        if (!success) {
            return { success: false };
        } else {
            dispatch({
                type: ActionTypes.SET_FORM_SUBMITTED,
                payload: { formId: form.id, isOtpResolved: true },
            });
            return { success: true };
        }
    };

    const handleOtpResend = async (): Promise<{ success: boolean }> => {
        const { success } = await sendDataToServer({
            submit: true,
        });

        return { success };
    };

    const handleOtpEditPhoneNumber = async () => {
        startLoadingForm();
        const { success } = await sendDataToServer({
            submit: true,
        });
        stopLoadingForm();
        return { success };
    };
    function onTransitionEnd(animation: TransitionEvent): void {
        const eventTarget = animation.target as HTMLElement;
        if (
            animation.propertyName === "transform" &&
            eventTarget?.id === "stepsWrapper"
        ) {
            handleFirstStepFieldFocus();
        }
    }

    const fillFormWithSharedFields = (sharedFields: {
        [x: string]: string;
    }) => {
        const newObj: { [x: string]: FieldData } = {};
        Object.keys(sharedFields).forEach((fieldElement) => {
            if (sharedFields[fieldElement]) {
                newObj[fieldElement] = {
                    valid: true,
                    errorMessage: "",
                    value: sharedFields[fieldElement],
                    changed: true,
                };
            }
        });
        dispatch({
            type: ActionTypes.SET_FIELDS_STATE,
            payload: { ...newObj },
        });
    };
    interface SharedFields {
        [x: string]: string;
    }

    const getSharedFields = async (): Promise<SharedFields | undefined> => {
        try {
            const sharedFields = await new Promise((resolve, reject) => {
                const data = localStorage.getItem("sharedFields");
                if (data) {
                    try {
                        const parsedData = JSON.parse(data) as SharedFields;
                        resolve(parsedData);
                    } catch (error) {
                        reject(error); // Reject the promise if JSON parsing fails
                    }
                } else {
                    resolve(undefined); // Resolve with undefined if sharedFields data is not found
                }
            });

            return sharedFields as Promise<SharedFields | undefined>;
        } catch (error) {
            return undefined;
        }
    };

    useEffect(() => {
        if (isSecondServiceForm) {
            const fetchSharedFields = async () => {
                const sharedFields: SharedFields | undefined =
                    await getSharedFields();
                if (sharedFields) {
                    fillFormWithSharedFields(sharedFields);
                }
            };
            void fetchSharedFields();
        }
    }, []);

    const handleCloseFormInsideExitModal = () => {
        if (!state.blockBack) {
            const sessionStorageFields = loadFieldsFromSessionStorage();
            const { steps } = form;

            if (sessionStorageFields) {
                dispatch({
                    type: ActionTypes.SET_FIELDS_STATE,
                    payload: cloneDeep(sessionStorageFields),
                });
            }

            const { fieldsWithDataDependencyAndDependentOn } =
                getFieldsDataDependency({
                    checkChangedFields: false,
                    changedFields: {},
                });

            void fetchFieldsDataDependency(
                fieldsWithDataDependencyAndDependentOn,
            );

            let newStep = 0;

            steps.forEach((step, index) => {
                const answered = step.fields.every(
                    (field) => sessionStorageFields?.[field.codeName].value,
                );
                if (answered) {
                    newStep = index;
                    window.history.pushState(null, "", `#step-${newStep + 1}`);
                    setWindowStep(newStep + 1);
                }
            });
            dispatch({
                type: ActionTypes.SET_STEP,
                payload: newStep,
            });
        }
        toggleShowFormInExitModal(false);
    };

    useEffect(() => {
        const animated = document.querySelector("#stepsWrapper");
        if (animated) {
            animated.addEventListener(
                "transitionend" as keyof ElementEventMap,
                onTransitionEnd,
                false,
            );
        }
        // (
        //     animated?.addEventListener as (
        //         type: string,
        //         listener: (event: TransitionEvent) => void,
        //         options?: { useCapture?: boolean; passive?: boolean } | boolean,
        //     ) => void
        // )("transitionend" as keyof ElementEventMap, onTransitionEnd, false);
        return () => {
            if (animated) {
                animated.removeEventListener(
                    "transitionend" as keyof ElementEventMap,
                    onTransitionEnd,
                    false,
                );
            }
            // (
            //     animated?.removeEventListener as (
            //         type: string,
            //         listener: (event: TransitionEvent) => void,
            //         options?:
            //             | { useCapture?: boolean; passive?: boolean }
            //             | boolean,
            //     ) => void
            // )("transitionend", onTransitionEnd, false);
        };
    }, []);

    useEffect(() => {
        if (currentStep === 0 && showFormModal) {
            toggleFormModal(false);
        } else if (
            currentStep > 0 &&
            (form.testConfig?.config?.formInsideModal || showInModal)
        ) {
            toggleFormModal(true);
        }
        setTimeout(() => {
            if (asPath.split("/")[1] !== "blog" && scrollToTop) {
                handleScrollFormTop();
            }
        }, 50);
        if (!form.stepToSendingData) {
            startSendingData = true;
        } else if (steps[currentStep]?.id === form.stepToSendingData) {
            startSendingData = true;
        }
    }, [currentStep]);

    const moveBack = async () => {
        const { blockBack } = await nextState();
        if (
            windowStep &&
            windowStep - 1 < currentStep &&
            !submitted &&
            !otp.isRequired &&
            !blockBack
        ) {
            dispatch({
                type: ActionTypes.SET_STEP,
                payload: windowStep - 1,
            });
        }
    };

    useEffect(() => {
        void moveBack();
    }, [windowStep]);

    useEffect(() => {
        const asyncFunction = async () => {
            const sessionStorageFields = loadFieldsFromSessionStorage();

            if (sessionStorageFields) {
                dispatch({
                    type: ActionTypes.SET_FIELDS_STATE,
                    payload: { ...sessionStorageFields },
                });
            }

            const { fieldsWithDataDependencyAndDependentOn } =
                getFieldsDataDependency({
                    checkChangedFields: false,
                    changedFields: {},
                });

            void fetchFieldsDataDependency(
                fieldsWithDataDependencyAndDependentOn,
            );

            if (isFormInsideOffersModal) {
                const firstStepFields = form.steps[0].fields;
                const answered = firstStepFields.every((field) => {
                    return !!sessionStorageFields?.[field.codeName]?.value;
                });
                if (answered) {
                    dispatch({
                        type: ActionTypes.SET_STEP,
                        payload: 1,
                    });
                }
            }
        };

        void asyncFunction();

        window.history.pushState("", document.title, `#step-1`);

        window.addEventListener("popstate", handleWindowBack);
        return () => {
            window.removeEventListener("popstate", handleWindowBack);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", sendDataToServerListener);
        return () => {
            window.removeEventListener(
                "beforeunload",
                sendDataToServerListener,
            );
        };
    }, []);

    return {
        handleFieldChange,
        handleFieldBlur,
        handlePrevStep,
        handleSubmit,
        handleOutsideFormSubmit,
        currentStep,
        steps,
        visitedSteps,
        fieldsData: fieldsState,
        isLoading: loadingForm,
        successMessage,
        jornayaIdElement,
        formError,
        formId: form.id,
        formErrors,
        formErrorsResolved,
        otp: {
            isRequired: otp.isRequired,
            isResolved: otp.isResolved,
            countDownTime: otp.countDown,
            handleSubmit: handleOtpSubmit,
            handleResend: handleOtpResend,
            handleEditPhoneNumber: handleOtpEditPhoneNumber,
            handleScrollFormTop,
        },
        formSubmitted: submitted,
        stepStatus: stepStatus,
        checkIfStepHasOneField,
        showExitModal,
        showRegulations,
        showCustomListingModal,
        setLoading,
        setHasRegulations,
        setShowCustomListingModal,
        showPiiCompletionOtherFields,
        setShowPiiCompletionOtherFields,
        showFormModal,
        toggleFormModal,
        listingSlug,
        toggleShowFormInExitModal,
        showFromInExitModal,
        handleCloseFormInsideExitModal,
    };
};

const useFields = (
    form: DomainForm,
): {
    fields: FieldsData;
} => {
    const clonedSteps = cloneDeep(form.steps);

    const temp: FieldsData = {};

    clonedSteps.forEach((step) => {
        step.fields.forEach((field) => {
            temp[field.codeName] = {
                value: field.defaultValue ?? "",
                valid: true,
                errorMessage: "",
                changed: false,
            };
        });
    });
    return { fields: temp };
};

const useSteps = (
    form: DomainForm,
    fieldsState: FieldsData | null,
): { steps: Step[]; setSteps: (steps: Step[]) => void } => {
    const [clonedSteps, setClonedSteps] = useState(cloneDeep(form.steps));

    clonedSteps.forEach((step, stepIndex: number) => {
        clonedSteps[stepIndex].title = decodeFieldsFromText(
            step.title,
            fieldsState!,
        );
        step.fields.forEach((field, fieldIndex) => {
            if (Array.isArray(clonedSteps[stepIndex].fields[fieldIndex].meta)) {
                clonedSteps[stepIndex].fields[fieldIndex].meta = mapValues(
                    keyBy(field.meta || [], "key"),
                    "value",
                );
            }
        });
    });

    return { steps: clonedSteps, setSteps: setClonedSteps };
};

const useSuccessStep = (
    successMessage: string,
    fieldsState: FieldsData | null,
    isSecondServiceForm: boolean,
) => {
    if (isSecondServiceForm) {
        const formattedSuccessMessage = decodeFieldsFromText(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            JSON.parse(localStorage.getItem("successMessage") as string),
            fieldsState!,
        );
        return formattedSuccessMessage;
    }
    const formattedSuccessMessage = decodeFieldsFromText(
        successMessage,
        fieldsState!,
    );
    return formattedSuccessMessage;
};

const decodeFieldsFromText = (text: string, fieldsState: FieldsData) => {
    let clonedText = text;

    const dynamicStrings = text?.match(/\${([^}]*)}/g);

    dynamicStrings?.forEach((item) => {
        if (fieldsState[item.replace("${", "").replace("}", "")]?.value) {
            const temp = clonedText.replace(
                item,
                fieldsState[item.replace("${", "").replace("}", "")]?.value,
            );
            clonedText = temp;
        }
    });

    return clonedText;
};
