import { Image } from "@lib/shared/types";
import React, { ReactElement } from "react";

export default function S3Icon({
    image,
    svgClassName,
}: {
    image: Image;
    svgClassName: string;
}): ReactElement {
    return (
        <span>
            {image?.mimeType === "image/svg+xml" && image.imageData ? (
                <span
                    className={`children:w-7 children:h-auto children:mx-auto ${svgClassName}`}
                    dangerouslySetInnerHTML={{ __html: image?.imageData }}
                ></span>
            ) : (
                <span className={`${svgClassName}`}>
                    <img src={image?.fullPath} alt={image.id} />
                </span>
            )}
        </span>
    );
}
