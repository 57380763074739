import React, { ReactElement, useState, useEffect } from "react";
import Modal from "@components/shared/modal";
import CloseIcon from "@components/svg/closeIcon";

export default function TCPA({
    content,
    className,
    buttonText,
}: {
    content: string | { TCPAMessage: string }[];
    className: string;
    buttonText?: string;
}): ReactElement {
    const [showPartnerPopup, setShowPartnerPopup] = useState(false);
    const [finalPopupContent, setFinalPopupContent] = useState<string | null>(
        null,
    );
    const extractTcpaMessagesFromContent = (arr: { TCPAMessage: string }[]) => {
        const arrayOfStrings = arr.map((el) => el.TCPAMessage);
        const tcpaArrayAfterRemoveDuplicate = new Set(arrayOfStrings);
        return [...tcpaArrayAfterRemoveDuplicate];
    };
    useEffect(() => {
        const partnersEl = document.getElementById("tcpa-Modal");
        const popupContent = partnersEl?.getElementsByTagName("span")[0];
        if (popupContent) {
            setFinalPopupContent(popupContent?.innerHTML.trim());
        }

        partnersEl?.addEventListener("click", () => {
            setShowPartnerPopup(true);
        });
    }, []);

    return (
        <>
            <input type="hidden" id="leadid_tcpa_disclosure"></input>
            <label
                htmlFor="leadid_tcpa_disclosure"
                style={{
                    height: "fit-content",
                    fontSize: "9px",
                    color: "#242626",
                }}
                className={`font-light leading-3 block mt-5 text-justify mb-5 ${
                    className ?? ""
                }`}
            >
                {Array.isArray(content) && content.length ? (
                    <>
                        {extractTcpaMessagesFromContent(content).map(
                            (tcpa, index) => (
                                <div
                                    key={index}
                                    className=" mt-5"
                                    dangerouslySetInnerHTML={{
                                        __html: tcpa?.replace(
                                            /\$\{btnText\}/g,
                                            buttonText ?? "submit",
                                        ),
                                    }}
                                />
                            ),
                        )}
                    </>
                ) : (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: (content as string)?.replace(
                                /\$\{btnText\}/g,
                                buttonText ?? "submit",
                            ),
                        }}
                    />
                )}
            </label>
            <Modal
                onClose={() => {
                    setShowPartnerPopup(false);
                }}
                active={showPartnerPopup && !!finalPopupContent}
                closeIcon={<CloseIcon />}
                backdropClosable
                modalStyles={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                }}
                modalClassName="h-[90vh] rounded"
                bodyStyles={{ overflowY: "scroll" }}
                width={900}
                header="Our Partners"
            >
                <div
                    className="pb-5 lg:px-9 px-5"
                    dangerouslySetInnerHTML={{
                        __html: finalPopupContent as string,
                    }}
                />
            </Modal>
        </>
    );
}
