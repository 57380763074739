import React from "react";

function MagnifyingGlass() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            fill="none"
            viewBox="0 0 17 17"
        >
            <path
                fill="#3C3C43"
                fillOpacity="0.6"
                d="M6.917 13.834a6.874 6.874 0 003.797-1.143l3.85 3.859c.254.246.58.369.931.369.73 0 1.266-.571 1.266-1.292 0-.334-.114-.66-.36-.905l-3.824-3.841a6.86 6.86 0 001.257-3.964C13.834 3.111 10.723 0 6.917 0 3.12 0 0 3.111 0 6.917c0 3.806 3.111 6.917 6.917 6.917zm0-1.846c-2.786 0-5.071-2.285-5.071-5.071 0-2.786 2.285-5.071 5.071-5.071 2.786 0 5.071 2.285 5.071 5.071 0 2.786-2.285 5.071-5.071 5.071z"
            ></path>
        </svg>
    );
}

export default MagnifyingGlass;
