// import useDebounce from "@hooks/useDebounce";
import { Field, ExpandableFieldMeta, FieldsData } from "@lib/shared/types";
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { getZipCodeSuggestions } from "src/api";
import styles from "./styles.module.scss";
import { arrayToKeyedObject } from "@lib/sharedUtils";
const zipCodeIds: string[] = ["zipCode", "destinationZipCode"];
interface InputProps {
    field: Field;
    fields: Field[];
    onChange: (f: Field, val: string) => void;
    value: string;
    className: string;
    type: React.HTMLInputTypeAttribute | undefined;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    fieldsData: FieldsData;
    lightBgColor?: string;
}
export interface ZipCodeSearchResponse {
    success: boolean;
    data: ZipCodeSearch[] | null;
}

export interface ExpandableFieldMeta {
    [x: string]: {
        addressComponentType: string;
        ignoreField: string;
        contentType: string;
        content: string;
    };
}
export interface ZipCodeSearch {
    zipCode: string;
    stateCode: string;
    city: string;
}
interface ExpandableFieldContent {
    ignoreField: string;
    contentType: string;
    fieldType: string;
    content: string;
    codeName: string;
}
interface ExpandableField {
    stateCode?: ExpandableFieldContent;
    city?: ExpandableFieldContent;
}

interface ExpandableFieldByCodeName {
    [x: string]: ExpandableFieldContent;
}
export default function ZipCodeExpandable(props: InputProps): ReactElement {
    const [isZipCodeChanged, setZipCodeChanged] = useState<boolean>(false);
    const [isSuggestionsListOpened, setSuggestionsListOpened] =
        useState<boolean>(false);

    const [preSelect, setPreSelect] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<ZipCodeSearch[]>([]);
    const [selectedZipCode, setZipCode] = useState<ZipCodeSearch | null>(null);

    const setSelectedZipCode = (zipCode: ZipCodeSearch | null) => {
        if (zipCode) {
            setZipCode(zipCode);
            sessionStorage.setItem("zipCode", JSON.stringify(zipCode));
        } else {
            setZipCode(null);
            sessionStorage.removeItem("zipCode");
        }
    };

    const {
        onChange,
        field,
        fields,
        className,
        value,
        fieldsData,
        lightBgColor,
    } = props;

    const expandableFields = useMemo(() => {
        const finalFields = JSON.parse(
            field.meta?.expandable as string,
        ) as ExpandableFieldMeta;
        const data: ExpandableFieldByCodeName = {};
        // excluding current field
        Object.keys(finalFields).forEach((key) => {
            if (key !== field.codeName) {
                data[key] = { ...finalFields[key] };
            }
        });
        return data;
    }, [field]);

    const expandableFieldsPerContentType = useMemo(() => {
        const finalFields = JSON.parse(
            field.meta?.expandable as string,
        ) as ExpandableFieldMeta;
        const data: ExpandableField = {};
        // excluding current field
        Object.keys(finalFields).forEach((key) => {
            if (key !== field.codeName) {
                const contentType = finalFields[key]?.content;
                data[contentType] = { ...finalFields[key], codeName: key };
            }
        });
        return data;
    }, [field]);

    const expandableFieldsCodeNames = useMemo(
        () => Object.keys(expandableFields),
        [expandableFields],
    );

    const fieldsToBeUpdated = useMemo(() => {
        return fields.filter((f) =>
            expandableFieldsCodeNames.includes(f.codeName),
        );
    }, [fields]);

    const activeZipCode = useMemo(() => {
        if (isZipCodeChanged && suggestions.length === 0) {
            return <></>;
        }

        let isDataExist = false;
        Object.keys(fieldsData).forEach((k) => {
            if (expandableFieldsCodeNames.includes(k) && fieldsData[k].value) {
                isDataExist = true;
            }
        });

        const className = `${
            styles["addressDetails"]
        } px-2 py-1 mt-1  text-sm rounded-sm ${
            field.meta?.zipcodeDetailsPosition === "inside"
                ? styles["zipcode-details-inside"]
                : ""
        }`;

        if (isDataExist && fieldsData[field.codeName]?.value) {
            return (
                <span className={className}>
                    <span>
                        {
                            fieldsData[
                                expandableFieldsPerContentType?.city
                                    ?.codeName as string
                            ]?.value
                        }
                        ,{" "}
                        {
                            fieldsData[
                                expandableFieldsPerContentType?.stateCode
                                    ?.codeName as string
                            ]?.value
                        }
                    </span>{" "}
                </span>
            );
        }
        if (selectedZipCode) {
            return (
                <span className={className}>
                    <span>
                        {selectedZipCode?.city}, {selectedZipCode.stateCode}
                    </span>
                </span>
            );
        }
        return <></>;
    }, [
        fieldsData,
        expandableFieldsCodeNames,
        field,
        expandableFields,
        expandableFieldsPerContentType,
        selectedZipCode,
    ]);

    const setExpandableFieldsValues = useCallback(
        (setData: boolean = true) => {
            // update Expandable Fields values
            fieldsToBeUpdated.forEach((f: Field) => {
                onChange(
                    f,
                    selectedZipCode
                        ? setData
                            ? selectedZipCode[
                                  expandableFields[f.codeName]
                                      .content as keyof ZipCodeSearch
                              ]
                            : ""
                        : "",
                );
            });
        },
        [selectedZipCode, fieldsToBeUpdated],
    );

    useEffect(() => {
        if (selectedZipCode) {
            setExpandableFieldsValues();
        }
    }, [selectedZipCode]);

    useEffect(() => {
        const clickOutside = (event: PointerEvent) => {
            if (
                !(event.target as HTMLInputElement).closest(
                    `.${field.codeName}-autocomplete`,
                )
            ) {
                setSuggestionsListOpened(false);
            }
        };
        document.addEventListener("click", clickOutside, false);

        return () => document.removeEventListener("click", clickOutside);
    }, []);

    useEffect(() => {
        const getData = setTimeout(async () => {
            if (!isZipCodeChanged) {
                return;
            }
            if (
                (value?.length >= 3 && !preSelect) ||
                (value?.length < 5 && preSelect)
            ) {
                const { data: result, error } = await getZipCodeSuggestions({
                    zipCode: value,
                });
                if (error) {
                    return;
                }
                setSuggestions(result as ZipCodeSearch[]);

                if (result?.length > 1) {
                    setSuggestionsListOpened(true);
                } else {
                    setSuggestionsListOpened(false);
                }
                if (result?.length === 1) {
                    setSelectedZipCode(result[0]);
                    setPreSelect(true);
                } else {
                    setSelectedZipCode(null);
                    setPreSelect(false);
                }
            }
            if (value?.length === 5) {
                setPreSelect(false);
            }
            if (value?.length === 0) {
                setSuggestions([]);
                setSelectedZipCode(null);
            }
        }, 250);
        return () => clearTimeout(getData);
    }, [value]);

    useEffect(() => {
        async function getInitialData() {
            try {
                const response = await getZipCodeSuggestions({
                    zipCode: fieldsData[field.codeName].value,
                });
                setSelectedZipCode(response.data[0]);
            } catch (e) {
                //
            }
        }
        if (
            fieldsData[field.codeName].value &&
            fieldsData[field.codeName].value.length === 5
        ) {
            let isDataExist = false;
            Object.keys(fieldsData).forEach((k) => {
                if (
                    expandableFieldsCodeNames.includes(k) &&
                    fieldsData[k].value
                ) {
                    isDataExist = true;
                }
            });
            if (!isDataExist) {
                const zipCodeFromSession = sessionStorage.getItem("zipCode");

                if (!zipCodeFromSession) {
                    void getInitialData();
                } else {
                    setSelectedZipCode(
                        JSON.parse(zipCodeFromSession) as ZipCodeSearch,
                    );
                }
            }
        }
    }, []);

    const selectZipCode = (suggestion: ZipCodeSearch, index: number) => {
        setSelectedZipCode({ ...suggestion, index });
        onChange(field, suggestion.zipCode);
        const fieldsKeyedObjects = arrayToKeyedObject(fields, "codeName");

        // fill expandableFields values
        Object.keys(expandableFields).forEach((k) => {
            onChange(
                fieldsKeyedObjects[k],
                suggestion[expandableFields[k].content as keyof ZipCodeSearch],
            );
        });
        setPreSelect(true);
        setSuggestionsListOpened(false);
    };

    const suggestionsList = useMemo(() => {
        if (value.length === 0 || value.length > 5) {
            return <></>;
        }

        if (suggestions.length > 1 && !selectedZipCode) {
            return (
                <div
                    id="suggestions-list"
                    className={`${styles["suggestionsWrapper"]} ${field.codeName}-autocomplete`}
                >
                    {suggestions.map((suggestion, index) => (
                        <div
                            key={index}
                            className={`${styles["suggestion"]} hover:font-bold text-sm`}
                            onClick={() => {
                                selectZipCode(suggestion, index);
                            }}
                        >
                            <span>{suggestion.zipCode}</span> -{" "}
                            <span>{suggestion.city}</span>,{" "}
                            <span>{suggestion.stateCode}</span>
                        </div>
                    ))}
                </div>
            );
        }

        return <></>;
    }, [
        suggestions,
        value,
        selectedZipCode,
        preSelect,
        fieldsData,
        expandableFieldsCodeNames,
    ]);

    return (
        <div className={`relative w-full ${className}`}>
            <div className={`flex items-stretch ${styles.inputWrapper}`}>
                {field.meta?.customLabel && (
                    <div
                        className={`${styles.customLabel} `}
                        style={{ backgroundColor: lightBgColor ?? "#efefef" }}
                    >
                        {field.meta?.customLabel}
                    </div>
                )}
                <div className={`w-full`}>
                    <input
                        value={value}
                        onChange={(e) => {
                            onChange(field, e.target.value);
                            setZipCodeChanged(true);
                        }}
                        onBlur={(e) => {
                            if (
                                zipCodeIds.includes(
                                    e.relatedTarget?.attributes.getNamedItem(
                                        "id",
                                    )?.value as string,
                                )
                            ) {
                                setSuggestionsListOpened(false);
                            }
                        }}
                        autoComplete="off"
                        role="presentation"
                        className={`${styles["field"]} ${
                            className ?? ""
                        } w-full`}
                        placeholder={field.placeholder ?? undefined}
                        type="tel"
                        name={field.codeName ?? undefined}
                        id={field.codeName}
                    />
                </div>
            </div>
            {fieldsData[field.codeName].valid && activeZipCode}
            {isSuggestionsListOpened && suggestionsList}
        </div>
    );
}
