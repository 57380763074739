import React, { ReactElement, useEffect, useState } from "react";
import { Field } from "src/lib/shared/types";
import styles from "./styles.module.scss";

export default function Checkbox({
    field,
    onChange,
    className,
    value,
    primaryColor,
    ignoreOptionIcons,
}: {
    field: Field;
    onChange: (val: string) => void;
    className: string;
    value: string;
    primaryColor?: string;
    ignoreOptionIcons?: boolean;
}): ReactElement {
    const [checkboxValue, setCheckboxValue] = useState(
        field.defaultValues?.options.map((option) => {
            if (value.includes(option.value)) return option.value;
            return "";
        }) ?? [],
    );

    const handleCheckboxChange = (value: string, index: number) => {
        const tempValue = [...checkboxValue];
        tempValue[index] ? (tempValue[index] = "") : (tempValue[index] = value);
        onChange(tempValue.filter((item) => item).join(","));
    };

    const renderOptions = () =>
        field.defaultValues?.options?.map((option, index) => (
            <div className={styles["option"]} key={index}>
                <input
                    type="checkbox"
                    id={`${field.codeName}-${index}`}
                    value={option.value}
                    name={field.codeName}
                    //@ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    onClick={(e) => handleCheckboxChange(e.target.value, index)}
                    checked={checkboxValue[index] === option.value}
                />
                <label key={index} htmlFor={`${field.codeName}-${index}`}>
                    <span
                        className={styles["box"]}
                        style={{
                            borderColor: primaryColor,
                            backgroundColor:
                                checkboxValue[index] === option.value
                                    ? primaryColor
                                    : undefined,
                        }}
                    >
                        <svg viewBox="0,0,50,50">
                            <path d="M5 30 L 20 45 L 45 5"></path>
                        </svg>
                    </span>
                    {option?.icon && !ignoreOptionIcons && (
                        <span className="flex items-center mr-2">
                            <img
                                src={option?.icon?.fullPath}
                                alt={option.label}
                                className="h-8 w-auto max-w-[50px]"
                            />
                        </span>
                    )}
                    <span
                        className="text-sm flex items-center"
                        style={{ transform: "translateY(1px)" }}
                    >
                        {option.label}
                    </span>
                </label>
            </div>
        ));

    useEffect(() => {
        setCheckboxValue(
            field.defaultValues?.options.map((option) => {
                if (value.includes(option.value)) return option.value;
                return "";
            }) ?? [],
        );
    }, [value]);

    return (
        <div
            className={`${styles["field"]} ${styles["checkbox"]} ${
                className ?? ""
            }`}
        >
            {renderOptions()}
        </div>
    );
}
