import React, { ReactElement, useMemo } from "react";
import { useDomainContext } from "@hooks/useDomainContext";

import VerticalOffer from "@components/shared/offerVertical";

import { affiliateOfferZones } from "@lib/shared/config";
import { doesOfferHaveALink } from "@lib/sharedUtils";

export default function OffersColumn(): ReactElement {
    const { thankYouOffers } = useDomainContext();

    const stickyOffer = useMemo(() => {
        if (thankYouOffers && thankYouOffers.length) {
            return thankYouOffers.find((offer) => offer.sticky);
        }
        return null;
    }, [thankYouOffers]);

    const finalThankYouOffers = useMemo(() => {
        if (thankYouOffers && thankYouOffers.length && stickyOffer) {
            return thankYouOffers.filter(
                (offer) => offer.id !== stickyOffer.id,
            );
        }
        return thankYouOffers;
    }, [thankYouOffers, stickyOffer]);

    return finalThankYouOffers?.length || stickyOffer ? (
        <>
            <div className="w-full pt-10 md:w-2/4 lg:w-1/3 px-0 sm:px-12">
                {stickyOffer ? (
                    <VerticalOffer
                        zone={affiliateOfferZones.THANK_YOU}
                        offer={stickyOffer}
                        displayLinkAndPhoneNumber={doesOfferHaveALink(
                            stickyOffer,
                        )}
                    />
                ) : (
                    <></>
                )}
                {finalThankYouOffers?.map((offer, index) => (
                    <VerticalOffer
                        zone={affiliateOfferZones.THANK_YOU}
                        key={index}
                        offer={offer}
                        displayLinkAndPhoneNumber={doesOfferHaveALink(offer)}
                    />
                ))}
            </div>
        </>
    ) : (
        <></>
    );
}
