import { useDomainContext } from "@hooks/useDomainContext";
import { useFormContext } from "@hooks/useFormContext";
import {
    InnerSecondServiceColors,
    Field,
    SecondServiceCategoryElement,
} from "@lib/shared/types";
import React, {
    ReactElement,
    useMemo,
    useReducer,
    CSSProperties,
    useEffect,
} from "react";
import { DomainForm, getDomainForm } from "src/api";
import Form from "@components/shared/form";

import secondServiceFormStyle from "./styles.module.scss";
import Spinner from "@components/shared/spinner";
import { Classes } from "@components/shared/form";
import { lightenColor } from "@lib/sharedUtils";

type SecondServiceFormsStateType = {
    loading: boolean;
    selectedServiceForm: DomainForm | null;
    selectedServiceSlug: string[];
    tags: { [x: string]: { slug: string; active: boolean; label: string } };
    error: Error | null;
    goToThankYou: boolean;
};
type Action =
    | {
          type: "SELECT_SERVICE";
          payload: { tag: string };
      }
    | {
          type: "INITIATE_SERVICES";
          payload: {
              tags: {
                  [x: string]: { slug: string; active: boolean; label: string };
              };
          };
      }
    | {
          type: "REMOVE_SERVICE";
          payload: { tag: string };
      }
    | {
          type: "SET_LOADING";
          payload: boolean;
      }
    | { type: "SELECT_SERVICE_ERROR"; payload: Error }
    | { type: "SET_SERVICE_FORM"; payload: DomainForm }
    | { type: "GO_TO_THANK_YOU" };

const initialSecondServiceFormsState: SecondServiceFormsStateType = {
    loading: false,
    selectedServiceForm: null,
    selectedServiceSlug: [],
    tags: {},
    error: null,
    goToThankYou: false,
};

const reducer = (
    state: SecondServiceFormsStateType,
    action: Action,
): SecondServiceFormsStateType => {
    switch (action.type) {
        case "REMOVE_SERVICE": {
            const selectedServiceSlug = [...state.selectedServiceSlug];
            selectedServiceSlug.splice(
                state.selectedServiceSlug.findIndex(
                    (elem) => elem === state.tags[action.payload.tag].slug,
                ),
                1,
            );
            const newTag = { ...state.tags };
            newTag[action.payload.tag].active = false;
            return {
                ...state,
                selectedServiceSlug: selectedServiceSlug,
                tags: newTag,
            };
        }
        case "SELECT_SERVICE": {
            const newTag = { ...state.tags };
            newTag[action.payload.tag].active = true;

            const selectedServiceSlug = state.selectedServiceSlug.includes(
                state.tags[action.payload.tag].slug,
            )
                ? [...state.selectedServiceSlug]
                : [
                      ...state.selectedServiceSlug,
                      state.tags[action.payload.tag].slug,
                  ];

            return {
                ...state,
                selectedServiceSlug,
                tags: newTag,
            };
        }

        case "INITIATE_SERVICES": {
            const slugs = Object.keys(action.payload.tags).map(
                (tag) => action.payload.tags[tag].slug,
            );
            return {
                ...state,
                tags: action.payload.tags,
                selectedServiceSlug: slugs,
            };
        }
        case "SET_LOADING": {
            return {
                ...state,
                loading: action.payload,
            };
        }

        case "SELECT_SERVICE_ERROR":
            return {
                ...state,
                loading: false,
                error: action.payload,
                selectedServiceForm: null,
            };
        case "SET_SERVICE_FORM":
            return {
                ...state,
                loading: false,
                error: null,
                selectedServiceForm: action.payload,
            };
        case "GO_TO_THANK_YOU":
            return {
                ...state,
                goToThankYou: true,
            };
        default:
            return state;
    }
};
const fieldsTypeToConvertToSelect = ["radio", "select"];
const fieldsTypeToConvertToMultipleSelect = ["checkbox"];
export default function SecondServiceForms({
    classes,
    colors,
    goToThankYou,
    secondServiceSearchableMultiSelect = false,
}: {
    classes?: Classes;
    colors?: InnerSecondServiceColors;
    goToThankYou: boolean;
    secondServiceSearchableMultiSelect?: boolean;
}): ReactElement {
    const { domain, category } = useDomainContext();
    const { setSkipToThankYou, isSecondServiceSubmitted } = useFormContext();

    const [secondServiceState, dispatch] = useReducer(
        reducer,
        initialSecondServiceFormsState,
    );

    const listOfServicesElements = useMemo<
        SecondServiceCategoryElement[]
    >(() => {
        return domain.categories
            .find((domainCategory) => domainCategory.slug === category?.slug)
            ?.secondServiceCategories?.map(
                (secondService) => secondService.secondServiceCategory,
            );
    }, [category]);

    useEffect(() => {
        if (listOfServicesElements && listOfServicesElements.length) {
            const slugs: string[] = [];
            const tags: {
                [x: string]: { slug: string; active: boolean; label: string };
            } = {};
            listOfServicesElements.forEach((service) => {
                slugs.push(service.slug);
                tags[service.label ?? service.name] = {
                    slug: service.slug,
                    active: false,
                    label: service.label ?? service.name,
                };
            });

            dispatch({
                type: "INITIATE_SERVICES",
                payload: { tags },
            });
        }
    }, [listOfServicesElements]);

    useEffect(() => {
        if (secondServiceState.tags) {
            getSecondServiceForm();
        }
    }, [secondServiceState.tags]);

    const groupingFormFields = (data: DomainForm) => {
        const fields: Field[] = [];
        data.steps.forEach((step) => {
            step.fields.forEach((field) => {
                if (fieldsTypeToConvertToSelect.includes(field.fieldType)) {
                    field.placeholder = "Select Option";
                    field.fieldType = "select";
                }
                if (
                    fieldsTypeToConvertToMultipleSelect.includes(
                        field.fieldType,
                    )
                ) {
                    field.fieldType = "multipleSelect";
                }
                field.tag = step.tag;
                fields.push(field);
            });
        });
        return fields;
    };
    const resetFirstStepFormTitles = (data: DomainForm) => {
        data.steps[0].title = "";
        data.steps[0].description = "";
    };
    const getServiceForm = async (categorySlug: string) => {
        if (categorySlug) {
            dispatch({
                type: "SET_LOADING",
                payload: true,
            });
            const { data, error } = await getDomainForm({
                categorySlug,
            });
            if (error) {
                dispatch({
                    type: "SELECT_SERVICE_ERROR",
                    payload: error,
                });
                return;
            }
            if (data) {
                const lastStep = data.steps[data.steps.length - 1];
                resetFirstStepFormTitles(data);
                const fields: Field[] = groupingFormFields(data);

                const firstStep = { ...data.steps[0] };
                firstStep.fields = fields;
                firstStep.TCPAMessage = lastStep.TCPAMessage;
                firstStep.buttonText = lastStep.buttonText;
                firstStep.includeTCPAMessage = lastStep.includeTCPAMessage;

                dispatch({
                    type: "SET_SERVICE_FORM",
                    payload: {
                        ...data,
                        stepToSendingData: 0,
                        steps: [firstStep],
                    },
                });
            }
            dispatch({
                type: "SET_LOADING",
                payload: false,
            });
        }
    };
    const toggleSecondService = (tag: string) => {
        dispatch({
            type: "SET_LOADING",
            payload: true,
        });
        if (secondServiceState.tags[tag].active) {
            dispatch({
                type: "REMOVE_SERVICE",
                payload: { tag },
            });
        } else {
            dispatch({
                type: "SELECT_SERVICE",
                payload: { tag },
            });
        }
        dispatch({
            type: "SET_LOADING",
            payload: false,
        });
    };

    const getSecondServiceForm = () => {
        const slugs = Object.keys(secondServiceState.tags)
            .filter((tag) => secondServiceState.tags[tag].active)
            .map((tag) => secondServiceState.tags[tag].slug);

        const allSlugs = Object.keys(secondServiceState.tags).map(
            (tag) => secondServiceState.tags[tag].slug,
        );

        slugs.length
            ? void getServiceForm(slugs.join(","))
            : void getServiceForm(allSlugs.join(","));
    };
    const outerSectionStyle = {
        "--checkbox-color":
            colors?.primaryColor ||
            "var(--global-form-buttons-background-color)",
        "--mainBackground-color": colors?.mainBackgroundColor,
        "--mainBorder-color": colors?.mainBorderColor,
        "--hoverRadio-color":
            colors?.hoverRadioColor ??
            lightenColor(colors?.primaryColor as string, 0.6),
        "--textHoverRadio-color": colors?.textHoverRadioColor,
        "--selectedRadio-color":
            colors?.selectedRadioColor ??
            lightenColor(colors?.primaryColor as string, 0.6),
        "--textSelectedRadio-color": colors?.textSelectedRadioColor,
        "--hoverCheckbox-color":
            colors?.hoverCheckboxColor ??
            lightenColor(colors?.primaryColor, 0.6),

        "--textHoverCheckbox-color": colors?.textHoverCheckboxColor,
        "--selectedCheckbox-color":
            colors?.selectedCheckboxColor ??
            (colors?.primaryColor
                ? lightenColor(colors?.primaryColor, 0.6)
                : "#eee"),
        "--textSelectedCheckbox-color": colors?.textSelectedCheckboxColor,
    } as CSSProperties;

    return (
        <div
            style={outerSectionStyle}
            className={`${secondServiceFormStyle.secondServiceFormWrapper} flex flex-col justify-between`}
        >
            <div
                className={secondServiceFormStyle.secondServiceFormInnerWrapper}
            >
                {!isSecondServiceSubmitted && (
                    <div id="second-service-step">
                        <h1 className="text-xl lg:text-2xl font-bold mb-2">
                            We Received your {category?.label ?? category?.name}{" "}
                            Request.
                        </h1>
                        <h2 className="text-base lg:text-xl font-light text-[#8F8F8F] mb-6">
                            Do you need a quote for another service?
                        </h2>
                    </div>
                )}
                {secondServiceState.selectedServiceForm ? (
                    <>
                        <Form
                            colors={{
                                ...colors,
                                primaryColor:
                                    colors?.primaryColor ||
                                    "var(--global-form-buttons-background-color)",
                            }}
                            classes={{
                                formClassName: `${
                                    classes?.formClassName as string
                                } ${
                                    secondServiceFormStyle["secondServiceForm"]
                                }`,
                                formButtonClassName: `${
                                    classes?.formButtonClassName as string
                                } ${secondServiceFormStyle["form-button"]}`,
                                formStepInnerClassName:
                                    classes?.formStepInnerClassName,
                                stepClassName: `${
                                    classes?.stepClassName as string
                                } ${secondServiceFormStyle["step1"]}`,

                                fieldsClassName: {
                                    zipcodeExpandable:
                                        classes?.fieldsClassName
                                            ?.zipcodeExpandable,
                                    textarea:
                                        classes?.fieldsClassName?.textarea,
                                    streetAddress:
                                        classes?.fieldsClassName?.streetAddress,
                                    select: `${
                                        classes?.fieldsClassName
                                            ?.select as string
                                    } ${secondServiceFormStyle["select"]}`,
                                    input: classes?.fieldsClassName?.input,
                                    date: classes?.fieldsClassName?.date,
                                    radio: `${
                                        classes?.fieldsClassName
                                            ?.radio as string
                                    } ${secondServiceFormStyle["radio-field"]}`,
                                    checkbox: `${
                                        classes?.fieldsClassName
                                            ?.checkbox as string
                                    } ${
                                        secondServiceFormStyle["checkbox-field"]
                                    }`,
                                    all: classes?.fieldsClassName?.all,
                                },
                                formFieldClassName: `${
                                    classes?.formFieldClassName as string
                                } ${secondServiceFormStyle["field-wrapper"]}`,
                                stepTitleClassName: classes?.stepTitleClassName,
                                stepDescriptionClassName:
                                    classes?.stepDescriptionClassName,
                                formProgressbarClassName:
                                    classes?.formProgressbarClassName,
                                labelAsTitleClassName:
                                    classes?.labelAsTitleClassName,
                                tcpaClassName: classes?.tcpaClassName,
                                fieldErrorMessageClassName:
                                    classes?.fieldErrorMessageClassName,
                                fieldNoteClassName: classes?.fieldNoteClassName,
                                fieldsWrapperClassName:
                                    classes?.fieldsWrapperClassName,
                                firstStepClass: classes?.firstStepClass,
                                formFieldDescriptionClassName:
                                    classes?.formFieldDescriptionClassName,
                                formFieldLabelClassName:
                                    classes?.formFieldLabelClassName,
                                formSubmitButtonClassName:
                                    classes?.formSubmitButtonClassName,
                                sellingPointClassName:
                                    classes?.sellingPointClassName,
                                stepTagClassName: classes?.stepTagClassName,
                            }}
                            showProgress={false}
                            form={secondServiceState.selectedServiceForm}
                            isSecondServiceForm={true}
                            goToThankYouPage={goToThankYou}
                            updateSecondServices={toggleSecondService}
                            tags={secondServiceState.tags}
                            activeSecondServices={
                                secondServiceState.selectedServiceSlug
                            }
                            secondServiceLoading={secondServiceState.loading}
                            searchableMultiSelect={
                                secondServiceSearchableMultiSelect
                            }
                        />
                    </>
                ) : (
                    <>
                        {secondServiceState.loading && (
                            <div className=" flex items-center w-full justify-center h-48">
                                <Spinner
                                    size={35}
                                    color={colors?.primaryColor || "#000"}
                                />
                            </div>
                        )}
                        {secondServiceState.error &&
                            !secondServiceState.loading && (
                                <span
                                    className={
                                        secondServiceFormStyle["errorMessage"]
                                    }
                                >
                                    Something Went Wrong
                                </span>
                            )}
                        <div className={secondServiceFormStyle["buttons"]}>
                            <button
                                className={`${
                                    classes?.formButtonClassName as string
                                } order-2 sm:order-1`}
                                style={{
                                    border: `1px solid ${
                                        colors?.primaryColor ||
                                        "var(--global-form-buttons-background-color)"
                                    }`,
                                    backgroundColor: "#fff",
                                    color: "#000",
                                }}
                                onClick={() => {
                                    setSkipToThankYou(true);
                                }}
                            >
                                No Thank You
                            </button>
                            <button
                                type="button"
                                style={{
                                    backgroundColor:
                                        colors?.primaryColor ||
                                        "var(--global-form-buttons-background-color)",
                                }}
                                className={`${
                                    classes?.formButtonClassName as string
                                } order-1 sm:order-2`}
                                disabled={
                                    !secondServiceState.selectedServiceSlug
                                        .length
                                }
                            >
                                Get My Free Quotes
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
