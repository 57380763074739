import axios from "axios";
import { AddressInfoResponse } from "@lib/shared/types";

export const getAddressInfo = async (
    search: string,
    allowedCountry: string,
): Promise<AddressInfoResponse> => {
    const addressInfo = await axios.get(
        `/api/getGoogleAddress?address=${search}&allowedCountry=${allowedCountry}`,
    );
    return addressInfo.data as AddressInfoResponse;
};
