import { useEffect } from "react";
import { useDomainContext } from "./useDomainContext";

export const useBingEnhancedConversion = () => {
    const { visitDetails, domain } = useDomainContext();
    useEffect(() => {
        if (
            visitDetails.trafficSourceNetwork?.toLocaleLowerCase() === "bing" &&
            domain.config?.useBingEnhancedConversion
        ) {
            const sharedFieldsData = JSON.parse(
                localStorage.getItem("sharedFields") as string,
            ) as {
                [x: string]: string;
            };

            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            window.uetq = window.uetq || [];

            const formattedPhoneNumber = formatPhoneNumber(
                sharedFieldsData?.phoneNumber,
            );
            const formattedEmail = formatEmail(sharedFieldsData?.email);

            if (formattedEmail || formattedPhoneNumber) {
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                window.uetq.push("event", "MyAction", {
                    pid: {
                        em: formattedEmail,
                        ph: formattedPhoneNumber,
                    },
                });
            }
        }
    }, []);
};

function formatEmail(email?: string) {
    if (!email) {
        return "";
    }
    return email
        .trim()
        .toLowerCase()
        .replace(/\+.+@/, "@")
        .replace(/\.+(?=@)/g, "")
        .replace(/\s/g, "")
        .replace(/^\.+|\.+$/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
}

function formatPhoneNumber(phoneNumber?: string) {
    if (!phoneNumber) {
        return "";
    }
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    const countryCode = !cleanedPhoneNumber.startsWith("1") ? "+1" : "+";

    const formattedPhoneNumber = countryCode + cleanedPhoneNumber;

    return formattedPhoneNumber;
}
