import React, {
    ChangeEvent,
    ClipboardEvent,
    KeyboardEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./styles.module.scss";
import findLastIndex from "lodash/findLastIndex";
import findIndex from "lodash/findIndex";
import { FormOtp } from "@hooks/useForm";

const digitMargin = 10;

export default function OTP({
    numberOfDigits,
    otp,
    isLoading,
    error,
    setError,
    otpValue,
    setOTP,
}: {
    numberOfDigits: number;
    otp: FormOtp;
    isLoading: boolean;
    error: boolean;
    setError: (val: boolean) => void;
    otpValue: string[];
    setOTP: (val: string[]) => void;
}): ReactElement {
    const [isFieldFocused, setIsFieldFocused] = useState(false);
    const otpFieldRef = useRef<HTMLInputElement>(null);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }

        if (e.key === "Delete" || e.key === "Backspace") {
            const index = findLastIndex(otpValue, (o) => o !== "");
            handleChange("", index);
        }
    };

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        const digitsRegex = new RegExp(/^[0-9]*$/);
        if (!digitsRegex.test(e.target.value)) return;

        if (e.target.value.length === 1) {
            if (error) setError(false);
            const index = findIndex(otpValue, (o) => o === "");
            handleChange(e.target.value, index);
        }
    };

    const handleChange = (value: string, index: number) => {
        const tempArray = [...otpValue];
        tempArray[index] = value;
        setOTP(tempArray);
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const digitsRegex = new RegExp(/^[0-9]*$/);

        const clipboardData = e.clipboardData.getData("Text");
        if (!digitsRegex.test(clipboardData)) return;

        const tempArray: string[] = new Array<string>(numberOfDigits).fill("");
        for (const [i, char] of clipboardData.split("").entries()) {
            if (digitsRegex.test(char)) {
                tempArray[i] = char;
            } else {
                break;
            }
        }
        setOTP(tempArray);
    };

    const handleSubmit = async () => {
        const { success } = await otp.handleSubmit(otpValue.join(""));
        if (!success) setOTP(new Array<string>(numberOfDigits).fill(""));
        setError(!success);
    };

    useEffect(() => {
        if (otpValue.every((item) => !!item)) {
            void handleSubmit();
        }
    }, [otpValue]);

    useEffect(() => {
        setIsFieldFocused(!isLoading);
        if (!isLoading && otpFieldRef) {
            setTimeout(() => {
                otpFieldRef.current?.focus();
            }, 100);
        }
    }, [isLoading]);

    return (
        <div className={`${styles["otp-field"]} relative`}>
            <div className="flex">
                {new Array(numberOfDigits).fill(0).map((item, index) => (
                    <div
                        key={index}
                        className={`${styles["otp-input"]} ${
                            isLoading ? styles["disabled"] : ""
                        } ${
                            isFieldFocused &&
                            findIndex(otpValue, (o) => o === "") === index
                                ? styles["focused"]
                                : ""
                        } ${error ? styles["error"] : ""} `}
                    >
                        <span>{otpValue[index]}</span>
                    </div>
                ))}
            </div>
            {!isLoading && (
                <input
                    ref={otpFieldRef}
                    style={{
                        width: isFieldFocused ? "45px" : "100%",
                        left: Math.max(
                            isFieldFocused
                                ? findIndex(otpValue, (o) => o === "") *
                                      (45 + digitMargin * 2)
                                : 0,
                            0,
                        ),
                    }}
                    name="otp"
                    type="tel"
                    autoComplete="off"
                    className={styles["otp-hidden-input"]}
                    maxLength={numberOfDigits}
                    onKeyDown={onKeyDown}
                    value=""
                    onChange={(e) => handleFieldChange(e)}
                    onFocus={() => setIsFieldFocused(true)}
                    onBlur={() => setIsFieldFocused(false)}
                    onPaste={handlePaste}
                />
            )}
        </div>
    );
}
