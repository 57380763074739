import { ListingItem } from "@lib/shared/types";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import { formatPhoneNumber, getPhoneNumber } from "@lib/sharedUtils";
import { useTranslation } from "@hooks/useTranslation";
import { callEvent } from "src/api";
export default function OffersInForm({
    offer,

    shape,
}: {
    offer: ListingItem | undefined;

    shape?: string;
}): ReactElement {
    const phoneNumber = getPhoneNumber(offer);
    const { translate } = useTranslation();
    return (
        <>
            {shape === "v2" && (
                <div
                    className={` ${styles["orStyles"]}  flex gap-2 justify-center items-center  mx-auto my-4`}
                >
                    <span className="border-dashed border-t-2 block w-[200px]"></span>
                    <span>
                        {" "}
                        {translate("OR") === "OR" ? "Or" : translate("OR")}
                    </span>
                    <span className="border-dashed border-t-2 block w-[200px]"></span>
                </div>
            )}
            <div className={`text-center  p-3   ${styles["container"]}`}>
                <h2 className={"font-bold text-base sm:text-lg leading-6 mb-1"}>
                    {translate("CALL_US_IMMEDIATE") === "CALL_US_IMMEDIATE"
                        ? "Call us Now for Immediate Assistance. It is 100% Free!"
                        : translate("CALL_US_IMMEDIATE")}
                </h2>
                <a
                    href={`tel:${phoneNumber as string}`}
                    onClick={() => {
                        if (phoneNumber) {
                            void callEvent({
                                event: "Call",
                                phoneNumber: phoneNumber,
                            });
                        }
                    }}
                >
                    <span
                        className={`underline font-bold text-lg sm:text-xl leading-9 ${styles["linkColor"]}`}
                    >
                        {formatPhoneNumber(phoneNumber)}
                    </span>
                </a>
            </div>
            {shape === "v1" && (
                <div
                    className={` ${styles["orStyles"]}  flex gap-2 justify-center items-center  mx-auto my-4`}
                >
                    <span className="border-dashed border-t-2 block w-[200px]"></span>
                    <span>
                        {" "}
                        {translate("OR") === "OR" ? "Or" : translate("OR")}
                    </span>
                    <span className="border-dashed border-t-2 block w-[200px]"></span>
                </div>
            )}
        </>
    );
}
