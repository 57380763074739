import React, { ReactNode } from "react";

export const RegulationsContent = ({
    title,
    description,
    children,
}: {
    title: string;
    description: string;
    children: ReactNode;
}) => {
    return (
        <div className="p-3">
            <div className="mb-4 flex text-center  p-3 rounded flex-col gap-5">
                <div>
                    <div>
                        <p className="pb-1">
                            <strong className="text-[22px]">{title}</strong>
                        </p>
                        <p className="text-[14px] text-[gray]">{description}</p>
                    </div>
                </div>
            </div>
            <div className="border border-t-[lightgray]">{children}</div>
        </div>
    );
};
