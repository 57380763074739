import { Field } from "@lib/shared/types";
import React, { ReactElement, useMemo } from "react";
import styles from "./styles.module.scss";
import { parseToDollars } from "@lib/sharedUtils";

const formatters = {
    dollars: parseToDollars,
    percentage: (val: string) => `${val}%`,
};

export default function Range({
    field,
    onChange,
    value,
    className,
}: {
    field: Field;
    onChange: (val: string) => void;
    value: string;
    className: string;
}): ReactElement {
    const minValue =
        field.minValue?.type === "number" ? field.minValue?.value ?? 0 : 0;
    const maxValue =
        field.maxValue?.type === "number" ? field.maxValue?.value ?? 0 : 100;

    const step = field.meta?.step ?? 1;

    const labelValue = useMemo(() => {
        if (value === minValue && field.meta?.minValueLabel)
            return field.meta?.minValueLabel;
        if (value === maxValue && field.meta?.maxValueLabel)
            return field.meta?.maxValueLabel;
        if (
            field.meta?.rangeValueFormatter === "dollars" ||
            field.meta?.rangeValueFormatter === "percentage"
        ) {
            return formatters[field.meta?.rangeValueFormatter](value);
        }

        return value ?? value;
    }, [value]);

    return (
        <div className="flex flex-col justify-center items-center">
            <p className={styles["range-value"]}>{labelValue}</p>
            <input
                onChange={(e) => onChange(e.target.value)}
                className={`${styles["range"]}  ${className ?? ""}`}
                name={field.codeName}
                value={value}
                id={field.codeName}
                type="range"
                min={minValue}
                max={maxValue}
                step={step}
            />
        </div>
    );
}
