import {
    ExpandableFieldMeta,
    AddressComponent,
    AddressInfoResponse,
    FormattedAddressComponent,
    StreetAddressProps,
    StreetAddress,
    ExpandableFieldsObject,
    Field,
} from "@lib/shared/types";
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styles from "./styles.module.scss";
import { getAddressInfo } from "src/api/searchAddress";
import Spinner from "@components/shared/spinner";
import AddressDetails from "./AddressDetails";
import { isStreetAddress, getZipCodeField } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";

const formattedGoogleAddress = (
    addressObject: AddressComponent[],
): FormattedAddressComponent => {
    const newAddressObject: FormattedAddressComponent = {};
    addressObject.forEach((addressComponent) => {
        const keyName = addressComponent.types[0];
        newAddressObject[keyName === "postal_code" ? "zipCode" : keyName] =
            addressComponent;
    });
    return newAddressObject;
};

let isPlaceSelected = false;
export default function StreetAddressField(
    props: StreetAddressProps,
): ReactElement {
    const {
        value,
        onChange,
        field,
        fields,
        className,
        type,
        fieldsData,
        colors,
        stepStatus,
        noteIndent,
    } = props;
    let autoComplete: google.maps.places.Autocomplete;
    const { selectedCountryCode } = useDomainContext();
    const streetAddressRef = useRef<HTMLInputElement>(null);
    const [addressContent, setAddressContent] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [inputFieldValue, setInputFieldValue] = useState(value);
    const [showAddressDetails, setShowAddressDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [onBlurOff, setDisableOnBlur] = useState(false);
    const resetFields = useMemo(() => {
        return Object.keys(
            JSON.parse(field.meta?.expandable as string) as ExpandableFieldMeta,
        );
    }, [field]);

    const inputHandler = useCallback(
        (val: string) => {
            setInputFieldValue(val);
            onChange(field, val);
            setTimeout(() => {
                if (val.trim() === "") {
                    setDisableOnBlur(false);
                    onChange(field, "");
                    const expandableFields = fields.filter((f) =>
                        resetFields.includes(f.codeName),
                    );
                    expandableFields.forEach((f) => {
                        onChange(f, "");
                    });
                    setAddressContent("");
                }
            }, 300);
        },
        [resetFields],
    );
    const disableOnBlur = useCallback(() => {
        setDisableOnBlur(true);
    }, []);

    useEffect(() => {
        const zipCodeField = getZipCodeField(fields);

        if (fieldsData[zipCodeField.codeName]?.value) {
            setTimeout(() => {
                const state = JSON.parse(
                    localStorage.getItem(`state`) as string,
                ) as string;
                const city = JSON.parse(
                    localStorage.getItem(`city`) as string,
                ) as string;

                if (state && city) {
                    setAddressContent(
                        `${city}, ${state} ${
                            fieldsData[zipCodeField.codeName]?.value
                        }`,
                    );
                }
            }, 300);
        }
    }, [fieldsData]);

    useEffect(() => {
        if (stepStatus === "error" && error) {
            setError("Invalid Street Address");
        }
    }, [stepStatus]);

    const handleGooglePlacesScriptLoaded = () => {
        if (streetAddressRef.current !== null) {
            autoComplete = new window.google.maps.places.Autocomplete(
                streetAddressRef.current,
                {
                    types: ["address"],
                    componentRestrictions: {
                        country: selectedCountryCode === "CA" ? "ca" : "us",
                    },
                    fields: [
                        "address_component",
                        "adr_address",
                        "alt_id",
                        "formatted_address",
                        "geometry",
                        "name",
                    ],
                },
            );
            autoComplete.addListener("place_changed", () =>
                handlePlaceSelect(),
            );
        }
    };

    const loadGooglePlacesScript = (callback: () => void) => {
        if (document.getElementById("googleStreetAddress")) {
            callback();
            return;
        }
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "googleStreetAddress";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${
            process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string
        }&libraries=places,geometry&callback=handleGooglePlacesScriptLoaded`;

        document.getElementsByTagName("head")[0].appendChild(script);
    };

    function clearFields() {
        const expandableFields = fields.filter((f) =>
            resetFields.includes(f.codeName),
        );
        expandableFields.forEach((field) => {
            onChange(field, "");
        });
    }

    async function getAddress(
        search: string,
        searchPart2: string = "",
        fromListAutoComplete: boolean = false,
    ) {
        setLoading(true);
        const searchKeywords = `${search} ${
            fromListAutoComplete ? searchPart2 : ""
        }`.trim();
        const addressResult: AddressInfoResponse = await getAddressInfo(
            encodeURIComponent(searchKeywords),
            selectedCountryCode === "CA" ? "CA" : "US",
        );
        setLoading(false);
        return addressResult;
    }

    useEffect(() => {
        setInputFieldValue(value);
    }, [value]);

    const isExpandable = useMemo(() => {
        // extract field meta
        const metaKeys: string[] = field.meta ? Object.keys(field.meta) : [];

        // check if field has expandable meta key
        return Array.isArray(metaKeys) && metaKeys.length
            ? metaKeys[0] === "expandable"
                ? true
                : false
            : false;
    }, [field]);

    const expandableList = useMemo(() => {
        if (isExpandable) {
            const list = Object.keys(
                JSON.parse(
                    field.meta?.expandable as string,
                ) as ExpandableFieldMeta,
            ).filter((key) => key !== field.codeName);
            return list;
        }
        return [];
    }, [field, fields, isExpandable]);

    const expandableFieldList = useMemo(() => {
        return fields
            ? fields.filter((f) => {
                  return expandableList.includes(f.codeName);
              })
            : [];
    }, [field, fields, expandableList]);

    const formatAddress = (addressObject: FormattedAddressComponent) => {
        if (isExpandable) {
            const expandableFields = field.meta?.expandable
                ? field.meta.expandable
                : "";

            let expandableFieldsObject: ExpandableFieldsObject = {};
            if (expandableFields) {
                expandableFieldsObject = JSON.parse(
                    expandableFields,
                ) as ExpandableFieldsObject;
            }

            const addressComponentsItem: StreetAddress = {};

            const fieldsElements: string[] | [] = expandableFields
                ? Object.keys(JSON.parse(expandableFields) as string[])
                : [];

            const fieldsElementsLength = fieldsElements.length;
            if (fieldsElementsLength) {
                for (let i = 0; i < fieldsElementsLength; i++) {
                    const codeName = fieldsElements[i];
                    const format =
                        expandableFieldsObject[codeName].format ?? "long_name";
                    if (isStreetAddress(codeName)) {
                        addressComponentsItem[
                            codeName as keyof StreetAddress
                        ] = `${addressObject.street_number?.short_name ?? ""} ${
                            addressObject.route?.long_name ?? ""
                        }`;
                    } else {
                        const addressObjectContent =
                            addressObject[
                                expandableFieldsObject[codeName]
                                    ?.contentType as keyof StreetAddress
                            ];
                        addressComponentsItem[codeName as keyof StreetAddress] =
                            addressObjectContent
                                ? (addressObjectContent[
                                      format as keyof AddressComponent
                                  ] as string)
                                : "";
                    }
                }
            }
            // const fieldsLength = fields.length;
            const state =
                addressObject.administrative_area_level_1?.short_name ?? "";
            const city = addressObject.locality?.short_name ?? "";
            const political = addressObject.political?.short_name ?? "";
            const zipCode = addressObject.zipCode?.short_name ?? "";
            for (let i = 0; i < fieldsElements.length; i++) {
                const x = fields.find((f) => f.codeName === fieldsElements[i]);
                onChange(
                    x as Field,
                    addressComponentsItem[
                        fieldsElements[i] as keyof StreetAddress
                    ] as string,
                );
            }
            localStorage.setItem(`state`, JSON.stringify(state));
            localStorage.setItem(`zipCode`, JSON.stringify(zipCode));
            localStorage.setItem(
                `city`,
                JSON.stringify(city ? city : political),
            );
            setInputFieldValue(
                `${addressObject.street_number?.short_name ?? ""} ${
                    addressObject.route?.long_name ?? ""
                }`,
            );
            setAddressContent(
                `${city ? city : political}, ${state} ${zipCode}`,
            );
        }
    };

    const validateAddress = (addressInfoResponse: AddressInfoResponse) => {
        if (addressInfoResponse.data.status !== "OK") {
            return false;
        }

        if (addressInfoResponse.data.status === "OK") {
            const streetNumber =
                addressInfoResponse.data.results[0]?.address_components.find(
                    (address) => address.types.includes("street_number"),
                )?.short_name;
            return streetNumber ? true : false;
        }
    };

    const checkAddress = (search: string) => {
        setTimeout(
            async function () {
                if (isPlaceSelected) {
                    isPlaceSelected = false;
                    return;
                }
                let validFields: boolean | string = true;

                expandableList.forEach(
                    (f) =>
                        (validFields =
                            fieldsData[f]?.value !== "" &&
                            fieldsData[f]?.valid &&
                            validFields),
                );
                validFields =
                    fieldsData[field.codeName].value !== "" &&
                    fieldsData[field.codeName].valid &&
                    validFields;

                if (!validFields) {
                    setAddressContent("");
                }
                // disable calling getAddressInfo on blur
                if (onBlurOff || validFields) {
                    setInputFieldValue(search);
                    onChange(field, search);
                    setError(null);
                    return;
                }

                setInputFieldValue(search);
                setShowAddressDetails(false);
                if (search.trim().length === 0) {
                    setAddressContent("");
                    clearFields();
                    return;
                }

                // call the geocoding api
                const addressResult: AddressInfoResponse = await getAddress(
                    search,
                );

                const isValidAddress = validateAddress(addressResult);
                if (isValidAddress) {
                    const address = formattedGoogleAddress(
                        addressResult.data.results[0]?.address_components,
                    );
                    formatAddress(address);
                    onChange(
                        field,
                        `${address.street_number?.long_name} ${address.route?.long_name}`,
                    );
                    setError(null);
                    // onBlur(field)
                    disableOnBlur();
                    return;
                }

                onChange(field, search);
                onChange(getZipCodeField(fields), "");
                setError("Invalid Street Address");
            }.bind(isPlaceSelected),
            450,
        );
    };

    const handlePlaceSelect = async () => {
        isPlaceSelected = true;
        setShowAddressDetails(false);
        const placeSelectResults = autoComplete.getPlace();

        if (!placeSelectResults?.address_components) {
            setError("Invalid Street Address");
            onChange(getZipCodeField(fields), "");

            return;
        }
        disableOnBlur();
        const address = formattedGoogleAddress(
            placeSelectResults.address_components,
        );
        const streetNumber = address?.street_number?.short_name ?? "";
        const route = address.route?.long_name ?? "";
        const state = address.administrative_area_level_1?.short_name ?? "";
        const city = address.locality?.short_name ?? "";
        const political = address.political?.short_name ?? "";
        const zipCode = address.zipCode?.short_name ?? "";

        setInputFieldValue(`${streetNumber} ${route}`.trim());

        const addressResult: AddressInfoResponse = await getAddress(
            `${streetNumber} ${route}`,
            `${city ? city : political ? political : zipCode}, ${state}`,
            true,
        );

        const isValidAddress = validateAddress(addressResult);
        if (isValidAddress) {
            const address = formattedGoogleAddress(
                addressResult.data.results[0]?.address_components,
            );
            onChange(field, `${streetNumber} ${route}`.trim());
            formatAddress(address);
            setError(null);
            return;
        }
        // clearFields();
        onChange(field, `${streetNumber} ${route}`.trim());
        onChange(getZipCodeField(fields), "");
        setError("Invalid Street Address");
    };

    const handleClickEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && field.fieldType === "streetAddress") {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (field.fieldType === "streetAddress") {
            loadGooglePlacesScript(handleGooglePlacesScriptLoaded);
            // @ts-ignore
            window["handleGooglePlacesScriptLoaded"] =
                handleGooglePlacesScriptLoaded;
        }
    }, []);

    return (
        <div className={`${styles["streetAddressFieldWrapper"]}`}>
            <div className={styles["streetAddressField"]}>
                <input
                    value={inputFieldValue}
                    onChange={(e) => inputHandler(e.target.value)}
                    className={`${styles["field"]} ${styles["streetField"]} ${
                        className ?? ""
                    }`}
                    placeholder={field.placeholder ?? undefined}
                    autoComplete={
                        field.fieldType === "streetAddress"
                            ? "off"
                            : field.autocomplete ?? undefined
                    }
                    type={type}
                    //@ts-ignore
                    onBlur={(e) => checkAddress(e.target.value)}
                    name={field.codeName ?? undefined}
                    id={field.codeName}
                    maxLength={
                        field.maxValue
                            ? parseInt(field.maxValue.value as string, 10)
                            : undefined
                    }
                    ref={
                        field.fieldType === "streetAddress"
                            ? streetAddressRef
                            : undefined
                    }
                    onKeyDown={handleClickEnter}
                />
                {loading && (
                    <div className={styles["loading"]}>
                        <Spinner size={30} color="green" success={false} />
                    </div>
                )}
            </div>

            {fieldsData[field.codeName]?.errorMessage ? (
                <div className={`${styles["error"]}`}>
                    <span>{fieldsData[field.codeName]?.errorMessage}</span>
                </div>
            ) : error ? (
                <div className={`${styles["error"]}`}>
                    <span>{error}</span>
                </div>
            ) : (
                <></>
            )}

            {!!(
                addressContent &&
                expandableFieldList.length &&
                !error &&
                !showAddressDetails
            ) && (
                <div
                    className={`${styles["addressDetails"]} px-2 py-1 mt-1  text-sm rounded-sm`}
                >
                    <span>{addressContent}</span>
                    <button
                        type="button"
                        onClick={() => {
                            setShowAddressDetails(!showAddressDetails);
                        }}
                        className={`${styles["address-details-button"]}`}
                        style={{
                            color: colors?.primaryColor ?? "#333",
                            borderColor: colors?.primaryColor ?? "#333",
                        }}
                    >
                        {showAddressDetails ? "Cancel" : "Change"}
                    </button>
                </div>
            )}
            {field.note && !addressContent && (
                <div
                    className={`${styles["field-note"]} ${
                        noteIndent as string
                    }`}
                >
                    <span>{field.note}</span>
                </div>
            )}
            {showAddressDetails && expandableFieldList.length && !error && (
                <AddressDetails
                    addressContent={addressContent}
                    expandableFieldList={expandableFieldList}
                    fieldsData={fieldsData}
                    colors={colors}
                    onChange={onChange}
                    setShowAddressDetails={setShowAddressDetails}
                    setAddressContent={setAddressContent}
                    disableOnBlur={disableOnBlur}
                />
            )}
        </div>
    );
}
