import React, { ReactElement } from "react";
import { Classes } from "..";
import styles from "./styles.module.scss";
import ProgressBar from "../progressBar";
import { Colors } from "@lib/shared/types";
import CloseIcon from "@components/severalbrands/blog/secondSection/sideWidget/closeIcon";

interface IFieldWrapperProps {
    type: "div" | "modal";
    children: ReactElement;
    classes: Classes | undefined;
    showProgress: boolean | undefined;
    current: number;
    total: number;
    colors: Colors;
    shift: number | undefined;
    showStepNumberOnly: boolean | undefined;
    showBackButton: boolean | undefined;
    backButton: IBackButton | undefined;
    handlePrevStep: () => void;
    onModalCloseClick?: () => void;
    tcpa?: ReactElement;
}

interface IWrapperComponentLookup {
    [type: string]: ReactElement;
}

interface IBackButton {
    class?: string;
    label?: string;
    firstStepAction?: () => void;
}

const FieldWrapper = ({
    type,
    children,
    classes,
    showProgress,
    current,
    total,
    colors,
    shift,
    showStepNumberOnly,
    showBackButton,
    backButton,
    handlePrevStep,
    onModalCloseClick,
    tcpa,
}: IFieldWrapperProps) => {
    const components: IWrapperComponentLookup = {
        div: (
            <div
                className={`${styles["inner"]} ${
                    classes?.formStepInnerClassName ?? ""
                }`}
            >
                {children}
            </div>
        ),
        modal: (
            <div className={styles["form-modal-container"]}>
                <div className={styles["form-modal-close-button"]}>
                    <button onClick={onModalCloseClick} type="button">
                        <CloseIcon />
                    </button>
                </div>
                <div className={styles["form-modal-wrapper"]}>
                    {children}
                    {showProgress && (
                        <ProgressBar
                            className={`${styles["progress-bar"]} ${
                                classes?.formProgressbarClassName ?? ""
                            }`}
                            current={current}
                            total={total}
                            color={colors?.progressBar}
                            shift={shift}
                            showStepNumberOnly={showStepNumberOnly}
                        />
                    )}
                    {tcpa}
                    {showBackButton && (
                        <button
                            type="button"
                            className={`${styles["back-button"]} ${
                                backButton?.class ?? ""
                            }`}
                            onClick={() =>
                                backButton?.firstStepAction && current === 0
                                    ? backButton.firstStepAction()
                                    : handlePrevStep()
                            }
                        >
                            {backButton?.label ?? "Previous"}
                            <i className={styles["arrow-back"]}></i>
                        </button>
                    )}
                </div>
            </div>
        ),
    };

    return components[type];
};

export default FieldWrapper;
