import { useDomainContext } from "@hooks/useDomainContext";

import styles from "./styles.module.scss";
import { replacePlaceholders } from "@lib/sharedUtils";
import { startCase } from "lodash";
const defaultText = `
    <p>
        Please confirm your details to receive your free quote.
    </p>
`;

export default function OtherFieldsHeadline() {
    const { domain, category, visitDetails, piiCompletionData } =
        useDomainContext();
    const textBlock = domain?.textBlocks?.find(
        (textBlock) => textBlock.position === "pii-completion-other-fields",
    );

    return (
        <div className="flex items-center flex-col">
            <div
                className={styles["pii-other-fields-headline"]}
                dangerouslySetInnerHTML={{
                    __html: replacePlaceholders(
                        textBlock?.text || defaultText,
                        {
                            category: category?.name,
                            region: visitDetails?.region,
                        },
                    ).replace(
                        "${codeName}",
                        startCase(piiCompletionData?.missingFields[0]) ??
                            "information",
                    ),
                }}
            />
        </div>
    );
}
