import React, { ReactElement } from "react";

export default function ArrowRightIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="14"
            fill="none"
            viewBox="0 0 21 14"
        >
            <path
                fill="#fff"
                d="M13.643 0l-1.41 1.41L16.813 6H.642v2h16.17l-4.59 4.59 1.42 1.41 7-7-7-7z"
            ></path>
        </svg>
    );
}
