import { useEffect, useRef } from "react";

export const usePrev = (value: any) => {
    const ref = useRef<{} | null>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ref.current = value;
    }, [value]);

    return ref.current;
};
