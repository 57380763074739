import { useDomainContext } from "@hooks/useDomainContext";
import styles from "./styles.module.scss";
import { replacePlaceholders } from "@lib/sharedUtils";
import { lowerCase } from "lodash";
const defaultText =
    "<h1>Almost There!</h1><p>Thank you for your interest! Please provide your ${codeName} below to complete your inquiry and receive a free quote.</p>";

export default function MissingFieldsHeadline() {
    const { domain, category, visitDetails, piiCompletionData } =
        useDomainContext();
    const textBlock = domain?.textBlocks?.find(
        (textBlock) => textBlock.position === "pii-completion-missing-field",
    );

    return (
        <div className="flex items-center flex-col">
            <div
                className={styles["pii-missing-field-headline"]}
                dangerouslySetInnerHTML={{
                    __html: replacePlaceholders(
                        textBlock?.text || defaultText,
                        {
                            category: category?.name,
                            region: visitDetails?.region,
                        },
                    ).replace(
                        "${codeName}",
                        piiCompletionData?.missingFields.length === 1
                            ? lowerCase(piiCompletionData?.missingFields[0])
                            : "information",
                    ),
                }}
            />
        </div>
    );
}
