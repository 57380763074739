import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function SmsTCPA(): ReactElement {
    const { domain, category } = useDomainContext();
    const router = useRouter();
    const showSmsTCPA = domain.config?.showSmsTCPA && !router.query.ts;

    return showSmsTCPA ? (
        <>
            <div className={styles["checkbox-wrapper"]}>
                <input type="checkbox" id="sms_tcpa" name="sms_tcpa" />
                <label
                    htmlFor="sms_tcpa"
                    className={`flex justify-center ${styles["checkbox-label"]} cursor-pointer`}
                >
                    <span className={styles["box"]}>
                        <svg viewBox="0,0,50,50">
                            <path d="M5 30 L 20 45 L 45 5"></path>
                        </svg>
                    </span>
                    <span>
                        I would like to receive SMS text messages from{" "}
                        {domain.niceName} about this{" "}
                        {category?.slug === "auto-accident"
                            ? "consultation"
                            : "quote"}{" "}
                        to the phone number provided above.
                    </span>
                </label>
            </div>
            <div className={styles["tcpa"]}>
                By checking the box above, message and data rates may apply. You
                can opt-out by replying STOP to any text at any time. For more
                information, please visit our{" "}
                <a target="_blank" href="/privacy-policy">
                    Privacy Policy
                </a>{" "}
                and{" "}
                <a target="_blank" href="/terms-and-conditions">
                    Terms of Use
                </a>
                . For assistance, contact us by texting "HELP" for help or
                "Help" at 406-204-3876.
            </div>
        </>
    ) : (
        <></>
    );
}
