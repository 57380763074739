/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useDomainContext } from "./useDomainContext";
// type Content

export const useTranslation = (): {
    translate: (key: string) => string;
} => {
    const { content } = useDomainContext();

    const translate = (key: string): string => {
        return (
            content?.content?.[key]
                ?.replace("{", "<span>")
                ?.replace("}", "</span>") ?? key
        );
    };
    return {
        translate,
    };
};
