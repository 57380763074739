import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function ProgressBar({
    total,
    current,
    className,
    color,
    shift = 0,
    showStepNumberOnly = false,
}: {
    total: number;
    current: number;
    className: string;
    color: string | undefined;
    shift?: number;
    showStepNumberOnly?: boolean;
}): ReactElement {
    const progress = (current + shift) / (total + shift - 1);

    const progressPercentage = Math.round(progress * 100);
    const hideStepsNumber = current + shift + 1 > total + shift - 1;
    return (
        <div
            className={`${styles["progress-bar"]} ${className} ${
                progressPercentage === 100 ? styles["success"] : ""
            }`}
        >
            {!showStepNumberOnly ? (
                <>
                    <div
                        style={{
                            left: progressPercentage
                                ? `${progressPercentage}%`
                                : 0,
                            transform: "translateX(-50%)",
                            background: color,
                        }}
                        className={styles["percentage"]}
                    >
                        <span className="text-xs">{progressPercentage}%</span>
                        <span
                            style={{ borderBottomColor: color }}
                            className={styles["arrow"]}
                        />
                    </div>
                    <div className={styles["bar"]}>
                        <span
                            style={{
                                width: `${progressPercentage}%`,
                                background: color,
                            }}
                        />
                    </div>
                </>
            ) : (
                <>
                    {!hideStepsNumber && (
                        <p className={styles["step-number"]}>
                            {current + shift + 1} of {total + shift - 1}
                        </p>
                    )}
                </>
            )}
        </div>
    );
}
