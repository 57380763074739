import { AnswerSummaryConfigs } from "..";
export type HandledPreDefinedAnswerSummaryConfigSlugs =
    | "autowarranty"
    | "auto-warranty";

const preDefinedAnswerSummaryConfigs: {
    [K in HandledPreDefinedAnswerSummaryConfigSlugs]?: AnswerSummaryConfigs;
} = {
    autowarranty: [
        // {
        //     stepIndex: 1,
        //     fields: [
        //         {
        //             codeName: "manufacturingYear",
        //             gridArea: "manufacturingYear",
        //             placeSelf: "center start",
        //         },
        //     ],
        //     gridTemplateAreas: `
        //         "manufacturingYear"
        //     `,
        //     gridTemplateColumns: "auto",
        //     gridTemplateRows: "auto",
        // },
        {
            stepIndex: 2,
            fields: [
                {
                    codeName: "manufacturingYear",
                    gridArea: "manufacturingYear",
                    placeSelf: "start start",
                    className: "ml-4 text-sm md:text-md",
                },
                {
                    codeName: "vehicleMake",
                    gridArea: "vehicleMake",
                    className: "text-sm md:text-xl ml-4",
                    placeSelf: {
                        sm: "center start",
                        md: "end start",
                    },
                },
                {
                    codeName: "vehicleModel",
                    gridArea: "vehicleModel",
                    className: "text-sm md:text-xl ml-2",
                    placeSelf: {
                        sm: "center start",
                        md: "end start",
                    },
                },
            ],
            gridTemplateAreas: `
                "vehicleMakeImage vehicleMake vehicleModel"
                "vehicleMakeImage manufacturingYear ."
            `,
            gridTemplateColumns:
                "fit-content(300px) fit-content(300px) fit-content(300px)",
            gridTemplateRows: "auto auto",
        },
        {
            stepIndex: 3,
            fields: [
                {
                    codeName: "manufacturingYear",
                    gridArea: "manufacturingYear",
                    placeSelf: "start start",
                    className: "ml-4 text-sm md:text-md",
                    appendText: " / ${vehicleMileage} Miles",
                },
                {
                    codeName: "vehicleMake",
                    gridArea: "vehicleMake",
                    className: "text-sm md:text-xl ml-4",
                    placeSelf: {
                        sm: "center start",
                        md: "end start",
                    },
                    appendText: " ${vehicleModel}",
                },
            ],
            gridTemplateAreas: {
                sm: `
                 "vehicleMakeImage vehicleMake vehicleMake"
                 "vehicleMakeImage manufacturingYear ."
                 "vehicleMileage vehicleMileage vehicleMileage"
                `,
                md: `
                "vehicleMakeImage vehicleMake vehicleMake vehicleMake"
                "vehicleMakeImage manufacturingYear vehicleMileage vehicleMileage"
               `,
            },
            gridTemplateColumns: {
                sm: "fit-content(300px) fit-content(300px) fit-content(300px)",
                md: "fit-content(300px) fit-content(300px) fit-content(300px)",
            },
            gridTemplateRows: "auto auto",
            extend: true,
        },
    ],
};

preDefinedAnswerSummaryConfigs["auto-warranty"] =
    preDefinedAnswerSummaryConfigs.autowarranty;

export default preDefinedAnswerSummaryConfigs;
