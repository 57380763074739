import { Field, Image } from "@lib/shared/types";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Spinner from "@components/shared/spinner";

export default function Select({
    field,
    onChange,
    value,
    className,
}: {
    field: Field;
    onChange: (val: string) => void;
    value: string;
    className: string;
}): ReactElement {
    const checkIfSelectHasMinMaxValue = () => {
        if (
            field.maxValue?.value &&
            field.minValue?.value &&
            field.maxValue.type === "number" &&
            field.minValue.type === "number" &&
            field.maxValue.value > field.minValue.value
        ) {
            return true;
        }
        return false;
    };

    const gettingArrayFromMaxMinValues = (): {
        label: number;
        value: number;
        icon: null | Image;
    }[] => {
        const newArray = [];
        for (
            let i: number = parseInt(field.minValue?.value as string, 10);
            i <= parseInt(field.maxValue?.value as string, 10);
            i++
        ) {
            newArray.unshift({
                label: i,
                value: i,
                icon: null,
            });
        }

        return newArray;
    };
    const renderOptions = () => {
        const options = checkIfSelectHasMinMaxValue()
            ? gettingArrayFromMaxMinValues()
            : field.defaultValues?.options;

        return (
            <>
                {options?.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </>
        );
    };

    return (
        <div style={{ position: "relative" }}>
            <div>
                <select
                    className={`${styles["field"]} ${styles["select"]} ${
                        !value ? styles["empty"] : ""
                    } ${className ?? ""}`}
                    onChange={(e) => onChange(e.target.value)}
                    name={field.codeName}
                    value={value}
                    disabled={field.fetchingFieldData}
                    id={field.codeName}
                >
                    {field.placeholder && (
                        <option value="">{field.placeholder}</option>
                    )}

                    {renderOptions()}
                </select>
            </div>
            {field.fetchingFieldData && (
                <div
                    style={{ position: "absolute", right: "50px", top: "10px" }}
                >
                    <Spinner size={30} color="green" success={false} />
                </div>
            )}
        </div>
    );
}
