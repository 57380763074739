import React, { ReactElement, useMemo } from "react";
import { useDomainContext } from "@hooks/useDomainContext";

import VerticalOffer from "@components/shared/offerVertical";
import styles from "../style.module.scss";

import { affiliateOfferZones } from "@lib/shared/config";
import { doesOfferHaveALink, replacePlaceholders } from "@lib/sharedUtils";
import Form from "@components/shared/form";

export default function Widget({ type }: { type: string }): ReactElement {
    const { thankYouOffers, domain, visitDetails } = useDomainContext();

    const stickyOffer = useMemo(() => {
        if (thankYouOffers && thankYouOffers.length) {
            return thankYouOffers.find((offer) => offer.sticky);
        }
        return null;
    }, [thankYouOffers]);

    const finalThankYouOffers = useMemo(() => {
        if (thankYouOffers && thankYouOffers.length && stickyOffer) {
            return thankYouOffers.filter(
                (offer) => offer.id !== stickyOffer.id,
            );
        }
        return thankYouOffers;
    }, [thankYouOffers, stickyOffer]);

    switch (type) {
        case "offers":
            return (
                <div
                    className={`flex flex-wrap gap-0 md:gap-5 mt-8 justify-center md:justify-start ${styles.widgetWrapper}`}
                >
                    {stickyOffer ? (
                        <VerticalOffer
                            zone={affiliateOfferZones.THANK_YOU}
                            offer={stickyOffer}
                            displayLinkAndPhoneNumber={doesOfferHaveALink(
                                stickyOffer,
                            )}
                        />
                    ) : (
                        <></>
                    )}
                    {finalThankYouOffers?.map((offer, index) => (
                        <VerticalOffer
                            zone={affiliateOfferZones.THANK_YOU}
                            key={index}
                            offer={offer}
                            displayLinkAndPhoneNumber={doesOfferHaveALink(
                                offer,
                            )}
                        />
                    ))}
                </div>
            );
        case "form":
            return (
                <div className={`${styles["main-banner"]}`}>
                    <Form
                        classes={{
                            formClassName: styles["form"],
                            stepTitleClassName: styles["step-title"],
                            stepDescriptionClassName:
                                styles["step-description"],
                            formButtonClassName: styles["form-button"],
                            formFieldClassName: styles["form-field"],
                            formStepInnerClassName: styles["steps-inner"],
                            stepClassName: styles["step"],
                            fieldsClassName: {
                                radio: styles["radio-field"],
                                checkbox: styles["checkbox-field"],
                                all: styles["field"],
                                select: styles["select-field"],
                                date: styles["date-filed"],
                            },
                            fieldsWrapperClassName: styles["fields-wrapper"],
                            tcpaClassName: styles["tcpa"],
                            labelAsTitleClassName:
                                styles["label-as-step-title"],
                            fieldNoteClassName: styles["field-note"],
                            fieldErrorMessageClassName:
                                styles["field-error-message"],
                            sellingPointClassName: styles["selling-point"],
                            formFieldLabelClassName: styles["form-field-label"],
                        }}
                        colors={{
                            primaryColor: "black",
                            progressBar: "#4992e9",
                        }}
                        showProgress={true}
                        goToThankYouPage={
                            domain.config?.goToThankYouPage ?? false
                        }
                        sellingPoint={
                            domain.config?.sellingPoint
                                ? replacePlaceholders(
                                      domain.config?.sellingPoint,
                                      {
                                          region: visitDetails.region,
                                          city: visitDetails?.city,
                                      },
                                  )
                                : ""
                        }
                        sellingPointsSteps={
                            domain.config?.sellingPointsSteps ?? "FIRST_STEP"
                        }
                        dynamicThankYouFields={
                            domain.config?.dynamicThankYouFields ?? [
                                "firstName",
                            ]
                        }
                        popupStyle={{
                            actionBtnBgColor:
                                domain.config?.popupStyle?.actionBtnBgColor ??
                                "black",
                            actionBtnColor:
                                domain.config?.popupStyle?.actionBtnColor ??
                                "#fff",
                            iconColor:
                                domain.config?.popupStyle?.iconColor ?? "black",
                        }}
                    />
                </div>
            );
        default:
            return <></>;
    }
}
