import React, { ReactElement } from "react";
import colors from "@styles/variables/businessphonepricesVariables.module.scss";
import { formatPhoneNumber, getPhoneNumber } from "@lib/sharedUtils";
import style from "./style.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import AffiliateOfferUrl from "../affiliateOfferUrl";
import { ListingItem } from "@lib/shared/types";
import { AffiliateOfferZone } from "@lib/shared/config";
import { getOffersByPlacement } from "@lib/sharedUtils";
export default function CompactOffer({
    offer,
    zone,
    classes,
    offerMessage,
    showAllOffers,
}: {
    offer?: ListingItem;
    zone: AffiliateOfferZone;
    classes: {
        container?: string;
        button?: string;
        image?: string;
    };
    offerMessage?: string;
    showAllOffers?: boolean;
}): ReactElement {
    const { domain } = useDomainContext();
    const offerItems = offer
        ? [{ ...offer }]
        : showAllOffers
        ? (getOffersByPlacement(
              domain?.defaultOffers,
              "thankYou",
          ) as ListingItem[])
        : [
              {
                  ...(
                      getOffersByPlacement(
                          domain?.defaultOffers,
                          "thankYou",
                      ) as ListingItem[]
                  )[0],
              },
          ];

    if (offerItems.length) {
        const offersList = offerItems.map((offerItem) => {
            const phoneNumber = getPhoneNumber(offerItem);
            const phoneNumberMessage = offerItem?.attributes.phoneNumberMessage
                ? offerItem?.attributes.phoneNumberMessage
                : offerItem?.hiddenAttributes.phoneNumberMessage
                ? offerItem?.hiddenAttributes.phoneNumberMessage
                : "";
            return (
                <div
                    key={offerItem.id}
                    className={`${style.container} ${
                        classes?.container ?? ""
                    } w-full  relative  mx-auto z-50 mb-[10px] px-4 py-2 border rounded-5`}
                    style={{
                        borderColor: "#e3e3e3",
                        background: "#F6F6F6",
                    }}
                >
                    <div className="flex flex-wrap items-center justify-center">
                        <div
                            className={`w-28 mt-4 mb-3 sm:mb-0 sm:mt-0  sm:border-r pr-0 md:pr-3 mr-0 md:mr-3 ${
                                classes?.image ?? ""
                            }`}
                            style={{
                                borderColor: colors.offerBorderColor,
                            }}
                        >
                            {offerItem.logo && (
                                <img
                                    src={offerItem.logo.fullPath}
                                    alt={offerItem.title}
                                />
                            )}
                        </div>
                        <div className="w-full sm:w-[200px]  flex-grow mb-3 sm:mb-0">
                            <h2
                                style={{ color: "#464c4d" }}
                                className="text-center text-[14px]"
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            offerMessage ??
                                            phoneNumberMessage ??
                                            phoneNumber
                                                ? "Call Now to Get the Best Deal"
                                                : "Review Offer and Coverage",
                                    }}
                                />
                            </h2>
                        </div>
                        <div className="min-w-[135px]  w-auto  mb-3 sm:mb-0">
                            <AffiliateOfferUrl
                                zone={zone}
                                style={{
                                    color: "white",
                                    backgroundColor: colors.autumnGlory,
                                }}
                                target="_blank"
                                offer={offerItem}
                                customHref={
                                    phoneNumber
                                        ? `tel:${phoneNumber ?? ""}`
                                        : undefined
                                }
                                className={`${style.link} ${
                                    classes?.button ?? ""
                                } flex justify-center rounded-5 text-center px-[10px] py-[6px] text-[15px]`}
                            >
                                {formatPhoneNumber(phoneNumber) ?? "View Deal"}
                            </AffiliateOfferUrl>
                        </div>
                    </div>
                </div>
            );
        });
        return <>{offersList}</>;
    }
    return <></>;
}
