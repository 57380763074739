/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Field, Image, Option } from "@lib/shared/types";
import React, { ReactElement, useState } from "react";
import styles from "./styles.module.scss";
import MagnifyingGlass from "@components/svg/magnifyingGlass";

export default function Radio({
    field,
    onClick,
    value,
    className,
    primaryColor,
    ignoreOptionIcons,
    isSecondServiceForm,
    optionSelectedIndex,
}: {
    field: Field;
    onClick: (val: string, optionSelectedIndex: string) => void;
    value: string;
    className: string;
    primaryColor?: string;
    ignoreOptionIcons?: boolean;
    isSecondServiceForm?: boolean;
    optionSelectedIndex?: string;
}): ReactElement {
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const renderOptions = () => {
        const options = checkIfRadioHasMinMaxValue()
            ? gettingArrayFromMaxMinValues()
            : field.defaultValues?.options;
        const totalPages =
            options?.length && field.styles?.perPage
                ? Math.ceil(options?.length / field.styles?.perPage)
                : 1;

        const metaIcons: { [x: string]: string } | undefined = JSON.parse(
            field.meta?.icons ?? "{}",
        ) as {} | undefined;
        return (
            <>
                {field?.styles?.withSearch && (
                    <span className={styles["search-wrapper"]}>
                        <input
                            type="text"
                            onChange={(e) =>
                                setSearchQuery(e.target.value?.toLowerCase())
                            }
                            className={styles["radio-search"]}
                            placeholder="Search"
                        />
                        <MagnifyingGlass />
                    </span>
                )}
                {/* @ts-ignore */}
                {!options?.filter((x: Option) =>
                    x.label?.toString()?.toLowerCase()?.includes(searchQuery),
                )?.length && <p className="mx-auto">No Results</p>}
                {(field?.styles?.perPage
                    ? options?.slice(
                          page * field.styles?.perPage,
                          field.styles?.perPage * (page + 1),
                      )
                    : options
                )
                    ?.filter((x: Option) => {
                        return x?.label
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(searchQuery);
                    })
                    ?.map((option: Option, index: number) => (
                        <div className={styles["option"]} key={index}>
                            <input
                                type="radio"
                                id={`${field.codeName}-${index}`}
                                value={option.value}
                                name={field.codeName}
                                // @ts-ignore
                                onClick={(e) =>
                                    onClick(
                                        e.target.value,
                                        `${field.codeName}-${index}`,
                                    )
                                }
                                onChange={() => {}}
                                checked={
                                    isSecondServiceForm
                                        ? option.value === value
                                        : option.value === value &&
                                          optionSelectedIndex ===
                                              `${field.codeName}-${index}`
                                }
                            />
                            <label
                                key={index}
                                htmlFor={`${field.codeName}-${index}`}
                                className={
                                    option?.icon && !ignoreOptionIcons
                                        ? styles["labelWithIcon"]
                                        : ""
                                }
                            >
                                {((option?.icon && !ignoreOptionIcons) ||
                                    metaIcons?.[option.label]) && (
                                    <div>
                                        <img
                                            src={
                                                option?.icon?.fullPath ||
                                                metaIcons?.[option.label]
                                            }
                                            alt={option?.label ?? ""}
                                            className="h-8 w-auto max-w-[50px]"
                                        />
                                    </div>
                                )}

                                <span
                                    className={`${option?.icon ? "mt-2" : ""} ${
                                        field.styles?.withTooltip
                                            ? styles["with-tooltip"]
                                            : ""
                                    } text-sm`}
                                >
                                    {option.label}
                                </span>
                            </label>
                        </div>
                    ))}
                {totalPages > 1 && page + 1 !== totalPages && (
                    <div
                        onClick={() => setPage(page + 1)}
                        className={`${styles["option"]} cursor-pointer`}
                        style={{
                            backgroundColor: primaryColor,
                            color: "white",
                            borderColor: primaryColor,
                        }}
                    >
                        <span className="text-sm">Next</span>
                    </div>
                )}
                {page ? (
                    <div
                        onClick={() => setPage(page - 1)}
                        className={`${styles["option"]} cursor-pointer`}
                        style={{
                            color: primaryColor,
                            borderColor: primaryColor,
                            backgroundColor: "white",
                        }}
                    >
                        <span className="text-sm">Back</span>
                    </div>
                ) : null}
            </>
        );
    };

    const checkIfRadioHasMinMaxValue = () => {
        if (
            field.maxValue?.value &&
            field.minValue?.value &&
            field.maxValue.type === "number" &&
            field.minValue.type === "number" &&
            field.maxValue.value > field.minValue.value
        ) {
            return true;
        }
        return false;
    };

    const gettingArrayFromMaxMinValues = (): {
        label: number;
        value: number;
        icon: null | Image;
    }[] => {
        const newArray = [];
        for (
            let i: number = parseInt(field.minValue?.value as string, 10);
            i <= parseInt(field.maxValue?.value as string, 10);
            i++
        ) {
            newArray.unshift({
                label: i,
                value: `${i}`,
                icon: null,
            });
        }

        return newArray;
    };

    return (
        <div
            className={`${styles["field"]} ${styles["radio"]} ${
                className ?? ""
            }`}
        >
            {renderOptions()}
        </div>
    );
}
