import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Field, Option } from "src/lib/shared/types";
import styles from "./styles.module.scss";

export default function Checkbox({
    field,
    onChange,
    className,
    value,
    primaryColor,
    ignoreOptionIcons,
    isSecondServiceForm,
    searchable = false,
    colorsConfig,
    classConfig,
    errorText,
    noteText,
}: {
    field: Field;
    onChange: (val: string) => void;
    className: string;
    value: string;
    primaryColor?: string;
    ignoreOptionIcons?: string;
    isSecondServiceForm?: boolean;
    searchable?: boolean;
    colorsConfig?: {
        tagColor?: string;
    };
    classConfig?: {
        fieldErrorMessageClassName?: string;
        fieldNoteClassName?: string;
    };
    errorText?: string | null;
    noteText?: string | null;
}): ReactElement {
    const [show, setShow] = useState(false);
    const [searchableValue, setSearchableValue] = useState("");
    const [checkboxValue, setCheckboxValue] = useState(
        field.defaultValues?.options.filter((option) =>
            value.includes(option.value),
        ),
    );

    const handleCheckboxChange = (value: string, remove?: boolean) => {
        const tempValue = [...(checkboxValue as Option[])];
        if (remove) {
            onChange(
                tempValue
                    .filter((option) => option.value !== value)
                    .map((el) => el.value)
                    .join(","),
            );
            return;
        }
        if (tempValue.find((option) => option.value === value)) {
            onChange(
                tempValue
                    .filter((option) => option.value !== value)
                    .map((el) => el.value)
                    .join(","),
            );
        } else {
            onChange(
                [...tempValue.map((option) => option.value), value].join(","),
            );
        }
    };

    const renderOptions = () =>
        field.defaultValues?.options
            ?.filter((option) =>
                option.label
                    .toLowerCase()
                    .includes(searchableValue.trim().toLowerCase()),
            )
            .map((option) => (
                <div className={styles["option"]} key={option.value}>
                    <input
                        type="checkbox"
                        id={`${field.codeName}-${option.value}`}
                        value={option.value}
                        name={field.codeName}
                        //@ts-ignore
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        onClick={(e: ChangeEvent<HTMLInputElement>) =>
                            handleCheckboxChange(e.target.value)
                        }
                        checked={
                            !!checkboxValue?.find(
                                (el) => el.value === option.value,
                            )
                        }
                    />
                    <label
                        key={option.value}
                        htmlFor={`${field.codeName}-${option.value}`}
                    >
                        <span
                            className={styles["box"]}
                            style={{
                                borderColor: primaryColor,
                                backgroundColor: checkboxValue?.find(
                                    (el) => el.value === option.value,
                                )
                                    ? primaryColor
                                    : undefined,
                            }}
                        >
                            <svg viewBox="0,0,50,50">
                                <path d="M5 30 L 20 45 L 45 5"></path>
                            </svg>
                        </span>
                        {option?.icon && !ignoreOptionIcons && (
                            <span className="flex items-center mr-2">
                                <img
                                    src={option?.icon?.fullPath}
                                    alt={option.label}
                                    className="h-8 w-auto max-w-[50px]"
                                />
                            </span>
                        )}
                        <span
                            className="text-sm flex items-center"
                            style={{ transform: "translateY(1px)" }}
                        >
                            {option.label}
                        </span>
                    </label>
                </div>
            ));
    useEffect(() => {
        setCheckboxValue(
            field.defaultValues?.options.filter((option) =>
                value.includes(option.value),
            ),
        );
    }, [value]);
    const getLabelsHandler = (value: string) => {
        const finalArray = value.split(",").map((el) => {
            const optionFound = field.defaultValues?.options.find(
                (option) => option.value === el,
            );
            return optionFound?.label.trim();
        });

        if (finalArray.length > 2) {
            return `${finalArray.slice(0, 1).join(", ")} and others`;
        }
        return finalArray.join(", ");
    };
    const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchableValue(e.target.value);
        setShow((prev) => {
            if (prev === false) {
                return true;
            }
            return prev;
        });
    };
    return (
        <div
            className={
                "relative" +
                ` ${className ?? ""} ${
                    isSecondServiceForm ? styles["no-background"] : ""
                }`
            }
            tabIndex={0}
            onBlur={(e) => {
                if (e.relatedTarget === null) {
                    setShow(false);
                    setSearchableValue("");
                }
            }}
        >
            <div
                className={`${styles["multipleSelectLabel"]} ${
                    errorText ? styles["errorBorder"] : ""
                }  ${
                    searchable
                        ? styles["multipleSelectLabelActive"]
                        : value
                        ? styles["multipleSelectLabelActive"]
                        : ""
                } ${
                    isSecondServiceForm
                        ? styles["isSecondServiceFormMultipleSelect"]
                        : ""
                }`}
                onClick={() => {
                    setShow((prev) => !prev);
                }}
                style={{ padding: searchable ? "5px 15px" : "10px 15px" }}
            >
                {searchable ? (
                    <input
                        type="text"
                        placeholder={
                            field.placeholder
                                ? field.placeholder
                                : "Select Multiple Options"
                        }
                        className={styles["multiSelectInput"]}
                        value={searchableValue}
                        onChange={changeInputHandler}
                    />
                ) : (
                    <>
                        {value
                            ? getLabelsHandler(value)
                            : field.placeholder
                            ? field.placeholder
                            : "Select Multiple Options"}
                    </>
                )}
            </div>
            {show && (
                <div
                    className={`${styles["multipleSelect"]} ${styles["field"]} ${styles["checkbox"]}`}
                >
                    {renderOptions()?.length === 0 ? (
                        <span className="flex justify-center items-center">
                            No Matches Found
                        </span>
                    ) : (
                        renderOptions()
                    )}
                </div>
            )}
            <span className="pt-2 block">
                {errorText && (
                    <div
                        className={`${styles["error-message"]}  ${
                            classConfig?.fieldErrorMessageClassName ?? ""
                        } `}
                    >
                        <span>{errorText}</span>
                    </div>
                )}
                {noteText && (
                    <div
                        className={`${styles["field-note"]} ${
                            classConfig?.fieldNoteClassName ?? ""
                        } `}
                    >
                        <span>{noteText}</span>
                    </div>
                )}
            </span>
            {searchable && (
                <div className="flex gap-3 mt-3 flex-wrap">
                    {(checkboxValue as Option[]).map((option) => (
                        <div
                            key={option.value}
                            className="border py-[5px] px-2 text-sm rounded-lg flex gap-3"
                            style={{
                                backgroundColor:
                                    colorsConfig?.tagColor ??
                                    primaryColor ??
                                    "black",
                                color: "#fff",
                            }}
                        >
                            <span>{option.label}</span>
                            <span
                                className="px-[6px] cursor-pointer"
                                onClick={() => {
                                    handleCheckboxChange(option.value, true);
                                }}
                            >
                                x
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
