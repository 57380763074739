import { useDomainContext } from "@hooks/useDomainContext";
import { Field, JSONValue } from "@lib/shared/types";
import React, { ReactElement, useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface InputProps {
    field: Field;
    onChange: (val: string) => void;
    onBlur: (val: string) => void;
    value: string;
    className: string;
}

let isSavedValueSet = false;

const months = [
    {
        label: "JAN",
        value: "0",
    },
    {
        label: "FEB",
        value: "1",
    },
    {
        label: "MAR",
        value: "2",
    },
    {
        label: "APR",
        value: "3",
    },
    {
        label: "MAY",
        value: "4",
    },
    {
        label: "JUN",
        value: "5",
    },
    {
        label: "JUL",
        value: "6",
    },
    {
        label: "AUG",
        value: "7",
    },
    {
        label: "SEP",
        value: "8",
    },
    {
        label: "OCT",
        value: "9",
    },
    {
        label: "NOV",
        value: "10",
    },
    {
        label: "DEC",
        value: "11",
    },
];

export default function DatePicker(props: InputProps): ReactElement {
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [htmlDate, setDate] = useState("");

    const { locale } = useDomainContext();

    const monthsTranslation =
        require(`@i18n/shared/date/${locale}.json`) as JSONValue;

    const translateMonths = (key: string): string =>
        monthsTranslation[key] ?? key;

    const { value, onChange, field, className } = props;

    const gettingYearFromDays = (days: number) => {
        const date = new Date();
        const last = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

        return last.getFullYear();
    };

    const gettingDateFromDays = (days: number) => {
        const date = new Date();
        const last = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

        return last.toISOString().split("T")[0];
    };

    const gettingYearOptions = () => {
        const maxDate = field?.maxValue?.value
            ? field.maxValue.type === "date"
                ? new Date(field.maxValue.value).getFullYear()
                : gettingYearFromDays(parseInt(field.maxValue.value, 10))
            : new Date().getFullYear();

        const minDate = field?.minValue?.value
            ? field.minValue.type === "date"
                ? new Date(field.minValue.value).getFullYear()
                : gettingYearFromDays(parseInt(field.minValue.value, 10))
            : 1920;

        const yearOptions = [];

        for (let i = minDate; i <= maxDate; i++) {
            yearOptions.push({ label: i, value: i });
        }
        return yearOptions.reverse();
    };

    const setDateValue = (date?: string) => {
        const finalDate = date
            ? new Date(date).toLocaleDateString("en-US")
            : new Date(
                  parseInt(year, 10),
                  parseInt(month, 10),
                  parseInt(day, 10),
              ).toLocaleDateString("en-US");

        onChange(finalDate);
    };

    useEffect(() => {
        if (isSavedValueSet && year && month && day) {
            setDateValue();
        }
    }, [year, month, day]);

    useEffect(() => {
        if (isSavedValueSet && htmlDate) {
            setDateValue(htmlDate);
        }
    }, [htmlDate]);

    useEffect(() => {
        if (!isSavedValueSet && value) {
            const currentDate = value ? new Date(value) : null;
            setDay(currentDate ? currentDate.getDate().toString() : "");
            setMonth(currentDate ? currentDate.getMonth().toString() : "");
            setYear(currentDate ? currentDate.getFullYear().toString() : "");
            setDate(currentDate ? currentDate.toString() : "");
        }
        setTimeout(() => {
            isSavedValueSet = true;
        }, 100);
    }, [value, field]);

    useEffect(() => {
        return () => {
            isSavedValueSet = false;
        };
    }, []);

    return (
        <>
            <div
                className={`${styles["date-picker"]} ${className ?? ""} ${
                    styles["desktop"]
                } hidden lg:flex`}
            >
                <select
                    className={`${styles["field"]} ${styles["select"]} ${
                        styles["month"]
                    } ${!month ? styles["empty"] : ""}`}
                    onChange={(e) => setMonth(e.target.value)}
                    value={month}
                >
                    <option value="">{translateMonths("MONTH")}</option>
                    {months.map((month, index) => (
                        <option key={index} value={month.value}>
                            {translateMonths(month.label)}
                        </option>
                    ))}
                </select>
                <select
                    className={`${styles["field"]} ${styles["select"]} ${
                        styles["day"]
                    } ${!day ? styles["empty"] : ""}`}
                    onChange={(e) => setDay(e.target.value)}
                    value={day}
                >
                    <option value="">{translateMonths("DAY")}</option>

                    {new Array(31)
                        .fill(0)
                        .map((day, index) => ({
                            label: index + 1,
                            value: index + 1,
                        }))
                        .map((day, index) => (
                            <option key={index} value={day.value}>
                                {day.label}
                            </option>
                        ))}
                </select>
                <select
                    className={`${styles["field"]} ${styles["select"]} ${
                        styles["year"]
                    } ${!year ? styles["empty"] : ""}`}
                    onChange={(e) => setYear(e.target.value)}
                    value={year}
                >
                    <option value="">{translateMonths("YEAR")}</option>

                    {gettingYearOptions().map((year, index) => (
                        <option key={index} value={year.value}>
                            {year.label}
                        </option>
                    ))}
                </select>
            </div>

            <div
                className={`${styles["date-picker"]} ${styles["mobile"]} ${
                    className ?? ""
                } lg:hidden`}
            >
                <input
                    value={value}
                    placeholder={
                        field.placeholder ? field.placeholder : "MM/DD/YYYY"
                    }
                    className={`${styles["field"]} ${styles["text-input"]}`}
                />
                <input
                    value={htmlDate}
                    onChange={(e) => setDate(e.target.value)}
                    className={`${styles["date-input"]}`}
                    placeholder={field.placeholder ?? undefined}
                    autoComplete={field.autocomplete ?? undefined}
                    type="date"
                    max={
                        field?.maxValue?.value !== null
                            ? field.maxValue.type === "date"
                                ? new Date(field.maxValue.value)
                                      .toISOString()
                                      .split("T")[0]
                                : gettingDateFromDays(
                                      parseInt(field.maxValue.value, 10),
                                  )
                            : undefined
                    }
                    min={
                        field?.minValue?.value !== null
                            ? field.minValue?.type === "date"
                                ? new Date(field.minValue.value)
                                      .toISOString()
                                      .split("T")[0]
                                : gettingDateFromDays(
                                      parseInt(field.minValue.value, 10),
                                  )
                            : undefined
                    }
                />
            </div>
        </>
    );
}
