import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

const ButtonArrow = (): ReactElement => {
    return (
        <span className={styles["button-arrow"]}>
            <span className={styles["inner"]}></span>
        </span>
    );
};

export default ButtonArrow;
