import React from "react";

function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="none"
            viewBox="0 0 13 13"
        >
            <path
                fill="#fff"
                d="M.963 12.46L0 11.501 5.294 6.23 0 .958.963 0l5.293 5.272L11.55 0l.963.958L7.219 6.23l5.294 5.271-.963.959-5.294-5.272L.963 12.46z"
            ></path>
        </svg>
    );
}

export default CloseIcon;
