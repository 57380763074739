import React, {
    FormEvent,
    MouseEvent,
    ReactElement,
    useEffect,
    useState,
} from "react";
import { Classes } from "@components/shared/form";
import { Field, Colors } from "@lib/shared/types";
import OtpField from "../fields/otp";
import formStyles from "../styles.module.scss";
import styles from "./styles.module.scss";
import Loader from "@components/shared/loader";
import { FormOtp } from "@hooks/useForm";
import Modal from "@components/shared/modal";
import { fieldValidation, handleFieldMasking } from "@lib/shared/form";
import fieldsStyles from "../fields/styles.module.scss";
import CloseIcon from "@components/svg/closeIcon";
import { useDomainContext } from "@hooks/useDomainContext";

let outerCounter = 0;
const numberOfDigits = 4;
let interval: NodeJS.Timeout;

export default function OtpForm({
    phoneNumber,
    className,
    colors,
    classes,
    isLoading,
    otp,
    handleFieldChange,
}: {
    phoneNumber: string;
    className: string;
    colors?: Colors;
    classes?: Classes;
    isLoading: boolean;
    otp: FormOtp;
    handleFieldChange: (
        field: Pick<Field, "mask" | "codeName">,
        val: string,
    ) => void;
}): ReactElement {
    const { locale } = useDomainContext();
    const [time, setTime] = useState(otp.countDownTime);
    const [isTimeActive, setIsTimerActive] = useState(false);
    const [error, setError] = useState(false);
    const [otpValue, setOTP] = React.useState<string[]>(
        new Array(numberOfDigits).fill(""),
    );
    const [isPhoneModalActive, setIsPhoneModalActive] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const [newPhoneNumberError, setNewPhoneNumberError] = useState<
        string | null
    >(null);
    const [oldPhoneNumber, setOldPhoneNumber] = useState(phoneNumber);

    const startTime = () => {
        if (!isTimeActive) {
            setIsTimerActive(true);
            interval = setInterval(() => {
                if (time > 1) {
                    setTime(outerCounter - 1);
                    outerCounter -= 1;
                }
                if (outerCounter === 0) {
                    clearInterval(interval);
                    setTime(otp.countDownTime);
                    outerCounter = otp.countDownTime;
                    setIsTimerActive(false);
                }
            }, 1000);
        } else {
            clearInterval(interval);
            setTime(otp.countDownTime);
            outerCounter = otp.countDownTime;
            interval = setInterval(() => {
                if (time > 1) {
                    setTime(outerCounter - 1);
                    outerCounter -= 1;
                }
                if (outerCounter === 0) {
                    clearInterval(interval);
                    setTime(otp.countDownTime);
                    outerCounter = otp.countDownTime;
                    setIsTimerActive(false);
                }
            }, 1000);
        }
    };

    const handleResendCode = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const { success } = await otp.handleResend();

        if (success) {
            startTime();
        }
    };

    const handleModalClose = () => {
        setIsPhoneModalActive(false);
        setTimeout(() => {
            otp.handleScrollFormTop();
        }, 50);
    };

    const getTimeLeft = () => {
        const minutes = Math.floor(time / 60);
        const seconds = (time - minutes * 60).toLocaleString("es-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });

        return `${minutes.toLocaleString("es-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })}:${seconds}`;
    };

    const validateNewPhoneNumber = async (value: string) => {
        const { message, valid } = await fieldValidation(
            {
                mask: "us-phone",
                isRequired: true,
                niceName: "Phone number",
                title: "Phone number",
                label: null,
                codeName: "",
                defaultValue: null,
                defaultValues: null,
                fieldType: "number",
                placeholder: null,
                pattern: null,
                minValue: null,
                maxValue: null,
                note: null,
                autocomplete: null,
                dependency: null,
                meta: null,
                conditionalLogic: null,
                validationType: "phoneNumber",
            },
            value,
            locale,
        );
        setNewPhoneNumberError(message ? message : null);
        return valid;
    };

    const handlePhoneNumberChange = (value: string) => {
        setNewPhoneNumberError(null);
        setNewPhoneNumber(handleFieldMasking("us-phone", value));
    };

    const handlePhoneNumberBlur = (value: string) => {
        void validateNewPhoneNumber(value);
    };

    const handlePhoneNumberEdit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (await validateNewPhoneNumber(newPhoneNumber)) {
            if (newPhoneNumber === oldPhoneNumber) {
                setNewPhoneNumberError("Please enter a new phone number");
            } else {
                handleFieldChange(
                    { mask: null, codeName: "phoneNumber" },
                    newPhoneNumber,
                );
            }
        } else {
            return;
        }
    };

    const handleEditPhoneNumberCancel = () => {
        setNewPhoneNumber("");
        setNewPhoneNumberError(null);
        handleModalClose();
    };

    const submitFormWithNewPhoneNumber = async () => {
        const { success } = await otp.handleEditPhoneNumber(newPhoneNumber);

        if (success) {
            setNewPhoneNumber("");
            setNewPhoneNumberError(null);
            handleModalClose();
            setOldPhoneNumber(phoneNumber);
            startTime();
        } else {
            setNewPhoneNumberError("Please enter a valid US phone number");
            handleFieldChange(
                { mask: null, codeName: "phoneNumber" },
                oldPhoneNumber,
            );
        }
    };

    useEffect(() => {
        outerCounter = otp.countDownTime;
        startTime();
    }, []);

    useEffect(() => {
        if (phoneNumber !== oldPhoneNumber) {
            void submitFormWithNewPhoneNumber();
        }
    }, [phoneNumber]);

    return (
        <div className={`${className ?? ""} overflow-hidden`} id="verify-otp">
            <div>
                <h2 className="text-xl font-bold">Verification Code</h2>
                <p className="font-light text-base mt-2 mb-4">
                    We need to verify your phone number{" "}
                    <span
                        className="font-bold inline-block"
                        style={{ color: "black" }}
                    >
                        {oldPhoneNumber}
                    </span>
                    , we are sending you the code now
                </p>

                <div
                    className={`${formStyles["form-field"]} ${styles["otp-field"]}`}
                >
                    <OtpField
                        numberOfDigits={numberOfDigits}
                        otp={otp}
                        setError={setError}
                        error={error}
                        isLoading={isLoading}
                        otpValue={otpValue}
                        setOTP={setOTP}
                    />

                    {error && (
                        <div
                            className={`${formStyles["error-message"]} ${styles["error-message"]}`}
                        >
                            Wrong code, try again
                        </div>
                    )}
                </div>
                <div className="text-center font-light mt-6 mb-7">
                    <h3 style={{ color: "grey" }} className="mb-5">
                        {!isTimeActive
                            ? "Didn't get the verification code?"
                            : "You can send another verification code in"}
                    </h3>
                    {isTimeActive ? (
                        <p>{getTimeLeft()}</p>
                    ) : (
                        <button
                            style={{ border: "none" }}
                            className={styles["button"]}
                            onClick={(e) => handleResendCode(e)}
                        >
                            Resend Code
                        </button>
                    )}
                    <button
                        style={{
                            color: colors?.primaryColor,
                            borderColor: colors?.primaryColor,
                        }}
                        className={styles["button"]}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsPhoneModalActive(true);
                        }}
                    >
                        Edit Phone Number
                    </button>
                </div>
                <button
                    style={{
                        backgroundColor: colors?.primaryColor,
                    }}
                    className={`${formStyles["submit-button"]} ${
                        classes?.formButtonClassName ?? ""
                    }`}
                    disabled={!otpValue.every((item) => !!item)}
                    type="submit"
                >
                    {isLoading ? (
                        <Loader size={30} />
                    ) : (
                        <>
                            <span>Verify</span>
                        </>
                    )}
                </button>
            </div>

            <Modal
                active={isPhoneModalActive}
                width={600}
                wrapperClassName={styles["phone-number-modal-wrapper"]}
                modalClassName="rounded-10"
                onClose={() => handleEditPhoneNumberCancel()}
                closeIcon={<CloseIcon />}
            >
                <div className="px-10 lg:px-16 py-10 font-poppins">
                    <h2 className="font-bold text-center text-xl lg:text-2xl mb-5">
                        Edit Your Phone Number
                    </h2>
                    <form onSubmit={handlePhoneNumberEdit}>
                        <div
                            className={`${formStyles["form-field"]} ${
                                newPhoneNumberError ? formStyles["error"] : ""
                            } ${classes?.formFieldClassName ?? ""}`}
                        >
                            <label
                                className={`${styles["field-label"]} ${
                                    classes?.formFieldLabelClassName ?? ""
                                } mb-2 font-light text-center`}
                                style={{ color: "grey" }}
                            >
                                Enter your phone number for a verification code
                            </label>
                            <input
                                className={`${
                                    classes?.fieldsClassName?.all ?? ""
                                } ${fieldsStyles["field"]}`}
                                value={newPhoneNumber}
                                type="tel"
                                onChange={(e) =>
                                    handlePhoneNumberChange(e.target.value)
                                }
                                onBlur={(e) =>
                                    handlePhoneNumberBlur(e.target.value)
                                }
                                placeholder="New phone number"
                            />
                            {newPhoneNumberError && (
                                <div className={formStyles["error-message"]}>
                                    {newPhoneNumberError}
                                </div>
                            )}
                        </div>
                        <button
                            style={{
                                backgroundColor: colors?.primaryColor,
                                width: "60%",
                            }}
                            className={`${formStyles["submit-button"]} ${
                                classes?.formButtonClassName ?? ""
                            } mx-auto`}
                            type="submit"
                        >
                            {isLoading ? (
                                <Loader size={30} />
                            ) : (
                                <>
                                    <span>Edit</span>
                                </>
                            )}
                        </button>
                    </form>

                    <button
                        onClick={() => handleEditPhoneNumberCancel()}
                        className={styles["button"]}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
}
