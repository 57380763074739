// import CheckIcon from "@components/svg/checkIcon";
import { ClickOffer, periods } from "@lib/shared/types";
import React, { ReactElement, useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import { parseToDollars } from "@lib/sharedUtils";

export default function MortgageOffer({
    offer,
}: {
    offer: ClickOffer;
}): ReactElement {
    const [popoverActive, setPopoverActive] = useState(false);
    function parseAmount(amount?: string): number {
        if (!amount) {
            return 0;
        }
        return parseInt(amount ? amount.replace(/[^0-9]/g, "") : "0");
    }

    const pointsAmount = parseAmount(offer.pointsAmount);
    const feesAmount = parseAmount(offer.feesAmount);
    const total = parseToDollars(String(pointsAmount + feesAmount));

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (popoverActive) {
            ref.current?.focus();
        }
    }, [popoverActive]);

    return (
        <div className={styles["lender-card"]}>
            <div className={styles["lender-metadata"]}>
                <div className={styles["lender-logo"]}>
                    <a target="_blank">
                        <img src={offer.logoUrl} alt={offer.brandName} />
                    </a>
                </div>
                <div className={styles["id-numbers"]}>
                    {offer.nmls && (
                        <div className={styles["nmls-id"]}>
                            NMLS #{offer.nmls}
                        </div>
                    )}

                    {offer.license?.map((license) => {
                        return (
                            <div key={license} className={styles["license-id"]}>
                                License #{license}
                            </div>
                        );
                    })}
                </div>
                {offer.period && (
                    <div className={styles["loan-type"]}>
                        {periods[offer.period]}
                    </div>
                )}
            </div>

            <div className={styles["apr"]}>
                <div className={styles["data-label"]}>
                    <span>APR</span>
                </div>
                <div className={styles["data-value"]}>{offer.APR}</div>
                {offer.period && (
                    <div className={styles["loan-type"]}>
                        {periods[offer.period]}
                    </div>
                )}
            </div>

            <div className={styles["payment"]}>
                <div className={styles["data-label"]}>
                    <span>Payment</span>
                </div>
                <div className={styles["data-value"]}>
                    {offer.monthlyPayment}&nbsp;<small>/mo</small>
                </div>
            </div>

            <div className={styles["rate"]}>
                <div className={styles["data-label"]}>
                    <span>Rate</span>
                </div>
                <div className={styles["data-value"]}>{offer.rate}</div>
            </div>

            {offer.period && (
                <div className={styles["loan-type"]}>
                    {periods[offer.period]}
                </div>
            )}

            <div className={styles["fees-and-points"]}>
                <div className={styles["data-label"]}>
                    <span>Fees &amp; Points</span>
                </div>
                <div className={styles["data-value"]}>
                    {total}
                    <div className={styles["fee-breakdown-and-button"]}>
                        <button
                            type="button"
                            className={styles["more-fee-info"]}
                            aria-expanded="false"
                            onClick={() => {
                                setPopoverActive((prev) => !prev);
                            }}
                        >
                            <Info />
                            {popoverActive && (
                                <div
                                    tabIndex={0}
                                    className={styles["fee-breakdown"]}
                                    ref={ref}
                                    onBlur={() => {
                                        setPopoverActive(false);
                                    }}
                                >
                                    <div>
                                        <p className={styles["header"]}>
                                            Fees &amp; Points
                                        </p>

                                        <p>
                                            <span>
                                                <b>{offer.points}</b> Points *
                                            </span>
                                            <span>
                                                {parseToDollars(
                                                    String(pointsAmount),
                                                )}
                                            </span>
                                        </p>

                                        <p>
                                            <span>Lender Fees</span>
                                            <span>
                                                {parseToDollars(
                                                    String(feesAmount),
                                                )}
                                            </span>
                                        </p>

                                        <p>
                                            <span>Total Fees</span>
                                            <span>{total}</span>
                                        </p>
                                    </div>
                                    <div className={styles["about-points"]}>
                                        * Points are equal to 1% of the loan
                                        amount and lower the interest&nbsp;rate.
                                    </div>
                                </div>
                            )}
                        </button>
                    </div>
                </div>
                <div className={styles["secondary-data-value"]}>
                    Includes {offer.points}&nbsp;Points (
                    {parseToDollars(String(pointsAmount))})
                </div>
            </div>

            <div className={styles["cta"]}>
                <a
                    target="_blank"
                    href={`/offer/${offer.slug}&isExternalOffer=true`}
                    className={styles["button"]}
                    rel="noreferrer"
                >
                    Next
                </a>
            </div>
        </div>
    );
}

function Info(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
        >
            <g>
                <circle
                    cx="9"
                    cy="9"
                    r="8"
                    fill="none"
                    stroke="#979797"
                    strokeWidth="2"
                    className="listing-more-fee-info-icon-stroke"
                ></circle>
                <circle
                    cx="9"
                    cy="5"
                    r="1"
                    fill="#979797"
                    className="listing-more-fee-info-icon-fill"
                ></circle>
                <line
                    x1="9"
                    y1="8"
                    x2="9"
                    y2="13"
                    fill="none"
                    stroke="#979797"
                    strokeLinecap="round"
                    strokeWidth="2"
                    className="listing-more-fee-info-icon-stroke"
                ></line>
            </g>
        </svg>
    );
}
